import { TCountriesDictionary } from '../types/TClient'

import { COUNTRY_PREFIX } from '../utils/countriesUtils'
import countries from './en/countries'
import { createCountryMessages } from '../utils/langUtils'

export type Message = {
  id: string,
  defaultMessage: string,
}

export type TCountryKeys = keyof TCountriesDictionary<typeof COUNTRY_PREFIX>

export type MessageKeys =
  | TCountryKeys
  | 'CurrentOrders'
  | 'Archive'
  | 'DownloadReport'
  | 'DownloadReportIn'
  | 'OrdersByUsers'
  | 'Filter'
  | 'SearchByOrders'
  | 'City'
  | 'CityShort'
  | 'Market'
  | 'Today'
  | 'Tomorrow'
  | 'Yesterday'
  | 'OrderFilter'
  | 'Customer'
  | 'Name'
  | 'Phone'
  | 'ClientType'
  | 'CallMeIf'
  | 'NotCallMeIf'
  | 'Collector'
  | 'AssignCollector'
  | 'NotBeenAssigned'
  | 'NotAssigned'
  | 'Picker'
  | 'AssignPicker'
  | 'SelectPicker'
  | 'Courier'
  | 'AssignCourier'
  | 'SelectCourier'
  | 'Save'
  | 'Cancel'
  | 'Receipt'
  | 'Address'
  | 'Pickup'
  | 'Delivery'
  | 'DeliveryDate'
  | 'DeliveryTime'
  | 'ChangeReceiveMethod'
  | 'OrderCreatedAt'
  | 'WebSite'
  | 'MobileApp'
  | 'OrderHistory'
  | 'AssemblyNotLaterThan'
  | 'TakenForAssembly'
  | 'BeingLate'
  | 'Min'
  | 'No'
  | 'DeliveryStartBefore'
  | 'TakenDelivery'
  | 'DeliveryToDoor'
  | 'Delivered'
  | 'NamePlace'
  | 'PickupTime'
  | 'PaymentLink'
  | 'DeliveryCost'
  | 'TotalPayable'
  | 'CustomerComment'
  | 'Comment'
  | 'EditComment'
  | 'AddComment'
  | 'Payment'
  | 'PaymentMethod'
  | 'Cash'
  | 'Card'
  | 'Refund'
  | 'ExtraPaid'
  | 'Surcharge'
  | 'Paid'
  | 'NotPaid'
  | 'SureRemoveItemFromOrder'
  | 'NotCancelRemoveItemOrder'
  | 'ValueMustNumber'
  | 'QuantityItemMustLess'
  | 'QuantityPcsMustInteger'
  | 'OrderList'
  | 'OrderWeight'
  | 'AddProducts'
  | 'AdditionalOrder'
  | 'Seller'
  | 'Place'
  | 'SettlementAmount'
  | 'NoSeller'
  | 'CustomerReview'
  | 'Assessment'
  | 'Review'
  | 'Date'
  | 'TimeSlot'
  | 'Status'
  | 'TotalOrder'
  | 'Number'
  | 'CollectorCourier'
  | 'KmFromMarket'
  | 'Picked'
  | 'Collected'
  | 'NotCollected'
  | 'Late'
  | 'SureChangeOrderStatus'
  | 'NotCancelChangeOrderStatus'
  | 'SureChangeOrderPayment'
  | 'NoCancelChangePaymentFromCashToCard'
  | 'OrderCannotCompleted'
  | 'OrdersProgress'
  | 'OnlyOnShift'
  | 'HideCollectors'
  | 'Collectors'
  | 'Couriers'
  | 'Managers'
  | 'History'
  | 'HistoryNotAvailable'
  | 'DuringAllTime'
  | 'ThisMonth'
  | 'LastMonth'
  | 'BeforeLastMonth'
  | 'OverTwoMonthsAgo'
  | 'TotalOrders'
  | 'LastOrder'
  | 'Monitoring'
  | 'CollectorsAndCouriers'
  | 'SlotsLoad'
  | 'ShiftSchedule'
  | 'ShiftIndicators'
  | 'OrdersMap'
  | 'Back'
  | 'Customers'
  | 'CustomersR'
  | 'UsersR'
  | 'SearchByPhoneName'
  | 'PhoneAndName'
  | 'FirstOrder'
  | 'Registration'
  | 'Orders'
  | 'DeliveryAddresses'
  | 'CustomerNoOrder'
  | 'ShowMore'
  | 'B2B'
  | 'B2C'
  | 'AverageBill'
  | 'From'
  | 'DelayedMinutes'
  | 'OrdersReviews'
  | 'CustomerNoReviews'
  | 'User'
  | 'OnModeration'
  | 'ProductsModerationRules'
  | 'SearchByProductOrEntity'
  | 'ProductPhoto'
  | 'ProductName'
  | 'Actions'
  | 'Approve'
  | 'Reject'
  | 'Category'
  | 'Subcategory'
  | 'PastValue'
  | 'Description'
  | 'TaskAt'
  | 'RejectionReason'
  | 'Approved'
  | 'Rejected'
  | 'New'
  | 'Repeated'
  | 'Pcs'
  | 'Kg'
  | 'Gram'
  | 'Litre'
  | 'Millilitre'
  | 'UnitMeasureShort'
  | 'SaleBy'
  | 'WeightOfOnePiece'
  | 'PurchaseStep'
  | 'PricePer'
  | 'EntityShort'
  | 'StoreNameAndNumber'
  | 'MarketingTags'
  | 'SelectMarket'
  | 'Add'
  | 'SureWantRemoveTag'
  | 'Tag'
  | 'ChangeProductTag'
  | 'ProductChange'
  | 'AddingProduct'
  | 'Sellers'
  | 'Markets'
  | 'Notifications'
  | 'Analytics'
  | 'Tags'
  | 'Moderation'
  | 'RegisterSeller'
  | 'FullRegistrationSeller'
  | 'InProcessRegistration'
  | 'ListTemplatesInProgress'
  | 'ShowcasesSellers'
  | 'InformationSellersProducts'
  | 'CopyProducts'
  | 'CopyingAllProductsInfo'
  | 'SystemVendors'
  | 'SellersInProcessRegistration'
  | 'SureDeleteSellerProfile'
  | 'Confirm'
  | 'ShowcaseSeller'
  | 'Missing'
  | 'SureDeleteProduct'
  | 'Hidden'
  | 'All'
  | 'Active'
  | 'HiddenS'
  | 'SellerPlace'
  | 'AddNewProduct'
  | 'EditingSellerShowcase'
  | 'Polygons'
  | 'Slots'
  | 'Assortment'
  | 'Catalog'
  | 'EditPolygons'
  | 'OpeningHours'
  | 'DeliveryHours'
  | 'MonSunFromTo'
  | 'SomeIntervalsNotSaved'
  | 'CannotCreateIntervalsOverlapAnother'
  | 'DeliverySlots'
  | 'DeliverySlotsNotSet'
  | 'HereConfigureDeliverySlots'
  | 'AddSlots'
  | 'AddSlot'
  | 'BestFromMarket'
  | 'Stalls'
  | 'SearchByProducts'
  | 'SearchBySellers'
  | 'ShopsNotFound'
  | 'ProductsNotFound'
  | 'BestFromMarketSearchEmpty'
  | 'StallsSearchEmpty'
  | 'AssortmentNotCreated'
  | 'HereCreateCategoriesSubcategories'
  | 'CreateAssortment'
  | 'CategoriesAndSubcategories'
  | 'Edit'
  | 'Categories'
  | 'Subcategories'
  | 'AddSubcategory'
  | 'FinishEditing'
  | 'SubcategoriesR'
  | 'GoodsR'
  | 'AddedProducts'
  | 'CategoryHidden'
  | 'CategoryHiddenInfo'
  | 'SubcategoryHidden'
  | 'SubcategoryHiddenInfo'
  | 'Other'
  | 'UpdateProductRatings'
  | 'SureUpdateRatingsInSubcategory'
  | 'UpdateRatingsWarning'
  | 'Refresh'
  | 'AddCategory'
  | 'CategoryName'
  | 'CategoryCurrentlyHidden'
  | 'CategoryIsVisible'
  | 'ClickCategoryVisible'
  | 'ClickHideCategory'
  | 'OnlyCategoryCannotHidden'
  | 'OnlyCategoryCannotDeleted'
  | 'RemovingCategory'
  | 'DeletingCategoryWarning'
  | 'ImageEditing'
  | 'SubcategoryName'
  | 'SubcategoryCurrentlyHidden'
  | 'SubcategoryIsVisible'
  | 'ClickSubcategoryVisible'
  | 'ClickHideSubcategory'
  | 'MarketName'
  | 'TagName'
  | 'SignIn'
  | 'Login'
  | 'EnterLogin'
  | 'Password'
  | 'EnterPassword'
  | 'Error'
  | 'FieldRequired'
  | 'ForSpecificCustomers'
  | 'SureRemoveNotification'
  | 'Notification'
  | 'Header'
  | 'Text'
  | 'DispatchTime'
  | 'Type'
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday'
  | 'DaysOfWeek'
  | 'Periodicity'
  | 'StartingFromCurrent'
  | 'HowManyWeeksRepeat'
  | 'UserGroup'
  | 'EnterClientNumberOrName'
  | 'NotLessAndNotMoreCharacters'
  | 'IncorrectFormat'
  | 'SentNotifications'
  | 'Quantity'
  | 'DepartureDate'
  | 'Single'
  | 'Scheduled'
  | 'AllUsers'
  | 'RegisteredUsers'
  | 'UnregisteredUsers'
  | 'UserType'
  | 'SignOut'
  | 'Yes'
  | 'Admin'
  | 'Manager'
  | 'Operator'
  | 'SelectTags'
  | 'ChangeHowReceive'
  | 'DeliveryAddress'
  | 'EnterAddress'
  | 'EnterDeliveryDate'
  | 'SpecifyDeliveryTime'
  | 'Apply'
  | 'Reset'
  | 'Delete'
  | 'QuantityGoods'
  | 'EnterTitle'
  | 'EditTag'
  | 'CreateTag'
  | 'Color'
  | 'Priority'
  | 'Image'
  | 'Download'
  | 'NoMoreThanCharacters'
  | 'StallsSellers'
  | 'ProductLinks'
  | 'ThroughCategories'
  | 'ThroughSeller'
  | 'Country'
  | 'VendorCode'
  | 'Rating'
  | 'Unit'
  | 'WeightOnePieceGrams'
  | 'SellBy'
  | 'DiscountPriceFor'
  | 'OriginalPrice'
  | 'UpdatePriceWithCommission'
  | 'PriceWithoutDiscountFor'
  | 'CreateCopy'
  | 'ValueMustGreaterThan'
  | 'ValueMustNotExceedCharacters'
  | 'ValueMustNumberGreaterThan'
  | 'MustAddImage'
  | 'Added'
  | 'ShopName'
  | 'SellerNameNickname'
  | 'PhotoYourShop'
  | 'SellerPhoto'
  | 'AssignOrder'
  | 'FieldMustNotEmpty'
  | 'Weight'
  | 'ForAmount'
  | 'InAssembly'
  | 'AtCourier'
  | 'OnDelivery'
  | 'Statuses'
  | 'Canceled'
  | 'Period'
  | 'Users'
  | 'NewRegisteredUsers'
  | 'DeliveredMany'
  | 'CanceledMany'
  | 'NumberCustomers'
  | 'NumberCustomersLeastOneOrder'
  | 'OfTheseNumberFirst'
  | 'NumberCustomersFirstOrder'
  | 'NumberOrdersFirstMonth'
  | 'AverageOrdersFirstMonth'
  | 'NumberOrdersLastMonth'
  | 'AverageOrdersLastMonth'
  | 'Turnover'
  | 'CommissionPlatform'
  | 'TotalWeight'
  | 'Total'
  | 'TotalStats'
  | 'OrderCard'
  | 'ItemRejection'
  | 'SpecifyReason'
  | 'DeliverySlot'
  | 'CustomerCard'
  | 'ItemsReport'
  | 'Polygon'
  | 'CopyingProducts'
  | 'SelectSellers'
  | 'SellerCopyFrom'
  | 'SellerCopyTo'
  | 'CopiedSuccessfully'
  | 'Completed'
  | 'PolygonsNotSet'
  | 'ConfigurePolygons'
  | 'AddPolygons'
  | 'AddPolygon'
  | 'PolygonDisabledToday'
  | 'PolygonDisabledUnlimited'
  | 'AvailableOrderTime'
  | 'PriceDelivery'
  | 'MinimumOrder'
  | 'AsSoonAsPossible'
  | 'BySlotNearest'
  | 'BySlotsThroughOne'
  | 'BySlotsThroughTwo'
  | 'AllDay'
  | 'AddAvailableTime'
  | 'Clear'
  | 'PolygonShutdown'
  | 'DisableDeliverySlotsToday'
  | 'DisableDeliverySlotsUnlimited'
  | 'SwitchOff'
  | 'FromWith'
  | 'Before'
  | 'SlotOverloaded'
  | 'CloseSlot'
  | 'OpenSlot'
  | 'SlotLoad'
  | 'FactPlan'
  | 'CouriersSlot'
  | 'PickersSlot'
  | 'ManagersSlot'
  | 'DownloadIndex'
  | 'CallMe'
  | 'NotCallMe'
  | 'IfProductOutOfStock'
  | 'PickReplacement'
  | 'DeleteProduct'
  | 'IfWeightItemChanged'
  | 'RemoveItemIfWeightChanges'
  | 'ChangeItemWeight'
  | 'AboutProductReplacement'
  | 'AboutDeletingItem'
  | 'IfWeightItemChanges'
  | 'IfNotAnswer'
  | 'DuringDay'
  | 'PayAttention'
  | 'Mon'
  | 'Tue'
  | 'Wed'
  | 'Thu'
  | 'Fri'
  | 'Sat'
  | 'Sun'
  | 'Daily'
  | 'ByDayWeek'
  | 'Once'
  | 'Weekly'
  | 'ToAllCustomers'
  | 'ToRegisteredCustomers'
  | 'ToNotRegisteredCustomers'
  | 'EveryWeeks'
  | 'NotShift'
  | 'OnShift'
  | 'DeliversOrder'
  | 'Free'
  | 'CollectsOrder'
  | 'ProductCreationError'
  | 'ProductUpdateError'
  | 'ProductDeletionError'
  | 'ProductCopyError'
  | 'ProductApproved'
  | 'ProductRejected'
  | 'Successfully'
  | 'ModerationUpdateError'
  | 'General'
  | 'CustomerOrders'
  | 'Products'
  | 'ProductsChain'
  | 'DefaultProductGroup'
  | 'DefaultProductGroupInfo'
  | 'CreateGroup'
  | 'SpecificProductsLinks'
  | 'SpecificProductsLinksInfo'
  | 'AddLinks'
  | 'ProductsSelected'
  | 'ResetAllProducts'
  | 'TieUp'
  | 'Change'
  | 'Group'
  | 'RemovingGroup'
  | 'RemovingGroupWarning'
  | 'ItemToTie'
  | 'WhatTie'
  | 'WhatToConnectWith'
  | 'Select'
  | 'Link'
  | 'Hide'
  | 'HideOff'
  | 'RemovingLink'
  | 'INN'
  | 'Selected'
  | 'SelectAnother'
  | 'InWork'
  | 'CourierRoutes'
  | 'Route'
  | 'ViewRoute'
  | 'ChangeRoute'
  | 'OrderStatus'
  | 'Set'
  | 'NotSet'
  | 'SetRoute'
  | 'Order'
  | 'DeliveryTo'
  | 'Assigned'
  | 'OnRoute'
  | 'BuildRoute'
  | 'Km'
  | 'Appoint'
  | 'AppointmentCourier'
  | 'Car'
  | 'Moto'
  | 'DeleteRouteWarning'
  | 'CannotUndone'
  | 'BreakFreeIn'
  | 'RouteCreatedSuccessfully'
  | 'AssemblyTo'
  | 'IsDeliveryTo'
  | 'ChangedAmount'
  | 'OrderCollected'
  | 'OrderPicked'
  | 'OrderPayed'
  | 'IsLate'
  | 'ProductAssembled'
  | 'ProductDeleted'
  | 'OrderCreated'
  | 'OrderInAssembly'
  | 'CourierTookOrderForDelivery'
  | 'OrderDeliveredToCustomer'
  | 'OrderCancelled'
  | 'Assign'
  | 'Week'
  | 'Month'
  | 'Quarter'
  | 'Year'
  | 'Source'
  | 'GeneralAll'
  | 'AllMarkets'
  | 'NumberOrdersPerUser'
  | 'TotalAmount'
  | 'AverageWeight'
  | 'NewUsers'
  | 'Profit'
  | 'FirstMonthUsersOrders'
  | 'LastMonthUsersOrders'
  | 'PaymentForGoods'
  | 'RepeatBuyers'
  | 'AverageForPeriod'
  | 'Currency'
  | 'SelectMarketFilter'
  | 'AddEmployee'
  | 'Surname'
  | 'CollectorR'
  | 'CourierR'
  | 'WorkingHours'
  | 'SlotsWork'
  | 'DeleteEmployee'
  | 'DeletingEmployee'
  | 'WarningDeletingEmployee'

const messages: Record<MessageKeys, Message> = {
  ...createCountryMessages(countries),
  CurrentOrders: {
    id: 'CurrentOrders',
    defaultMessage: 'Current orders',
  },
  Archive: {
    id: 'Archive',
    defaultMessage: 'Archive',
  },
  DownloadReport: {
    id: 'DownloadReport',
    defaultMessage: 'Download report',
  },
  DownloadReportIn: {
    id: 'DownloadReportIn',
    defaultMessage: 'Download report in {format}',
  },
  OrdersByUsers: {
    id: 'OrdersByUsers',
    defaultMessage: 'Orders by buyers',
  },
  Filter: {
    id: 'Filter',
    defaultMessage: 'Filter',
  },
  SearchByOrders: {
    id: 'SearchByOrders',
    defaultMessage: 'Search by order number, address or phone number',
  },
  City: {
    id: 'City',
    defaultMessage: 'City',
  },
  CityShort: {
    id: 'CityShort',
    defaultMessage: 'c.',
  },
  Market: {
    id: 'Market',
    defaultMessage: 'Market',
  },
  Today: {
    id: 'Today',
    defaultMessage: 'Today',
  },
  Tomorrow: {
    id: 'Tomorrow',
    defaultMessage: 'Tomorrow',
  },
  Yesterday: {
    id: 'Yesterday',
    defaultMessage: 'Yesterday',
  },
  OrderFilter: {
    id: 'OrderFilter',
    defaultMessage: 'Order filter',
  },
  Customer: {
    id: 'Customer',
    defaultMessage: 'Customer',
  },
  Name: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  Phone: {
    id: 'Phone',
    defaultMessage: 'Phone',
  },
  ClientType: {
    id: 'ClientType',
    defaultMessage: 'Client type',
  },
  CallMeIf: {
    id: 'CallMeIf',
    defaultMessage: 'Call me if',
  },
  NotCallMeIf: {
    id: 'NotCallMeIf',
    defaultMessage: 'Don\'t call me if',
  },
  Collector: {
    id: 'Collector',
    defaultMessage: 'Collector',
  },
  AssignCollector: {
    id: 'AssignCollector',
    defaultMessage: 'Assign collector',
  },
  NotBeenAssigned: {
    id: 'NotBeenAssigned',
    defaultMessage: 'Has not been assigned',
  },
  NotAssigned: {
    id: 'NotAssigned',
    defaultMessage: 'Not assigned',
  },
  Picker: {
    id: 'Picker',
    defaultMessage: 'Picker',
  },
  AssignPicker: {
    id: 'AssignPicker',
    defaultMessage: 'Assign picker',
  },
  SelectPicker: {
    id: 'SelectPicker',
    defaultMessage: 'Select picker',
  },
  Courier: {
    id: 'Courier',
    defaultMessage: 'Courier',
  },
  AssignCourier: {
    id: 'AssignCourier',
    defaultMessage: 'Assign courier',
  },
  SelectCourier: {
    id: 'SelectCourier',
    defaultMessage: 'Select courier',
  },
  Save: {
    id: 'Save',
    defaultMessage: 'Save',
  },
  Cancel: {
    id: 'Cancel',
    defaultMessage: 'Cancel',
  },
  Receipt: {
    id: 'Receipt',
    defaultMessage: 'Receipt',
  },
  Address: {
    id: 'Address',
    defaultMessage: 'Address',
  },
  Pickup: {
    id: 'Pickup',
    defaultMessage: 'Pickup',
  },
  Delivery: {
    id: 'Delivery',
    defaultMessage: 'Delivery',
  },
  DeliveryDate: {
    id: 'DeliveryDate',
    defaultMessage: 'Delivery date',
  },
  DeliveryTime: {
    id: 'DeliveryTime',
    defaultMessage: 'Time of delivery',
  },
  ChangeReceiveMethod: {
    id: 'ChangeReceiveMethod',
    defaultMessage: 'Change how you receive',
  },
  OrderCreatedAt: {
    id: 'OrderCreatedAt',
    defaultMessage: 'The order was made on {date} at {time}',
  },
  WebSite: {
    id: 'WebSite',
    defaultMessage: 'Web site',
  },
  MobileApp: {
    id: 'MobileApp',
    defaultMessage: 'Mobile app',
  },
  OrderHistory: {
    id: 'OrderHistory',
    defaultMessage: 'Order history',
  },
  AssemblyNotLaterThan: {
    id: 'AssemblyNotLaterThan',
    defaultMessage: 'Assembly, no later than',
  },
  TakenForAssembly: {
    id: 'TakenForAssembly',
    defaultMessage: 'Taken for assembly/collected',
  },
  BeingLate: {
    id: 'BeingLate',
    defaultMessage: 'Being late',
  },
  Min: {
    id: 'Min',
    defaultMessage: 'Min',
  },
  No: {
    id: 'No',
    defaultMessage: 'No',
  },
  DeliveryStartBefore: {
    id: 'DeliveryStartBefore',
    defaultMessage: 'Delivery, start before',
  },
  TakenDelivery: {
    id: 'TakenDelivery',
    defaultMessage: 'Taken for delivery',
  },
  DeliveryToDoor: {
    id: 'DeliveryToDoor',
    defaultMessage: 'Delivery to the door to',
  },
  Delivered: {
    id: 'Delivered',
    defaultMessage: 'Delivered',
  },
  NamePlace: {
    id: 'NamePlace',
    defaultMessage: 'Name',
  },
  PickupTime: {
    id: 'PickupTime',
    defaultMessage: 'Pickup time',
  },
  PaymentLink: {
    id: 'PaymentLink',
    defaultMessage: 'Payment Link',
  },
  DeliveryCost: {
    id: 'DeliveryCost',
    defaultMessage: 'Cost of delivery',
  },
  TotalPayable: {
    id: 'TotalPayable',
    defaultMessage: 'Total payable',
  },
  CustomerComment: {
    id: 'CustomerComment',
    defaultMessage: 'Customer\'s comment',
  },
  Comment: {
    id: 'Comment',
    defaultMessage: 'Comment',
  },
  EditComment: {
    id: 'EditComment',
    defaultMessage: 'Edit comment',
  },
  AddComment: {
    id: 'AddComment',
    defaultMessage: 'Add comment',
  },
  Payment: {
    id: 'Payment',
    defaultMessage: 'Payment',
  },
  PaymentMethod: {
    id: 'PaymentMethod',
    defaultMessage: 'Payment method',
  },
  Cash: {
    id: 'Cash',
    defaultMessage: 'Cash',
  },
  Card: {
    id: 'Card',
    defaultMessage: 'Card',
  },
  Refund: {
    id: 'Refund',
    defaultMessage: 'Refund',
  },
  ExtraPaid: {
    id: 'ExtraPaid',
    defaultMessage: 'Extra paid',
  },
  Surcharge: {
    id: 'Surcharge',
    defaultMessage: 'Surcharge',
  },
  Paid: {
    id: 'Paid',
    defaultMessage: 'Paid',
  },
  NotPaid: {
    id: 'NotPaid',
    defaultMessage: 'Not paid',
  },
  SureRemoveItemFromOrder: {
    id: 'SureRemoveItemFromOrder',
    defaultMessage: 'Are you sure you want to remove the item from the order?',
  },
  NotCancelRemoveItemOrder: {
    id: 'NotCancelRemoveItemOrder',
    defaultMessage: 'It will not be possible to return an item to an order',
  },
  ValueMustNumber: {
    id: 'ValueMustNumber',
    defaultMessage: 'Value must be a number',
  },
  QuantityItemMustLess: {
    id: 'QuantityItemMustLess',
    defaultMessage: 'Quantity of goods must be less than current',
  },
  QuantityPcsMustInteger: {
    id: 'QuantityPcsMustInteger',
    defaultMessage: 'Quantity of piece goods must be an integer',
  },
  OrderList: {
    id: 'OrderList',
    defaultMessage: 'Order list',
  },
  OrderWeight: {
    id: 'OrderWeight',
    defaultMessage: 'Order weight',
  },
  AddProducts: {
    id: 'AddProducts',
    defaultMessage: 'Add products',
  },
  AdditionalOrder: {
    id: 'AdditionalOrder',
    defaultMessage: 'Additional order',
  },
  Seller: {
    id: 'Seller',
    defaultMessage: 'Seller',
  },
  Place: {
    id: 'Place',
    defaultMessage: 'Place',
  },
  SettlementAmount: {
    id: 'SettlementAmount',
    defaultMessage: 'Settlement amount',
  },
  NoSeller: {
    id: 'NoSeller',
    defaultMessage: 'No seller',
  },
  CustomerReview: {
    id: 'CustomerReview',
    defaultMessage: 'Customer review',
  },
  Assessment: {
    id: 'Assessment',
    defaultMessage: 'Assessment',
  },
  Review: {
    id: 'Review',
    defaultMessage: 'Review',
  },
  Date: {
    id: 'Date',
    defaultMessage: 'Date',
  },
  TimeSlot: {
    id: 'TimeSlot',
    defaultMessage: 'Time slot',
  },
  Status: {
    id: 'Status',
    defaultMessage: 'Status',
  },
  TotalOrder: {
    id: 'TotalOrder',
    defaultMessage: 'Total for the order',
  },
  Number: {
    id: 'Number',
    defaultMessage: 'Number',
  },
  CollectorCourier: {
    id: 'CollectorCourier',
    defaultMessage: 'Collector/Courier',
  },
  KmFromMarket: {
    id: 'KmFromMarket',
    defaultMessage: 'Km from market',
  },
  Picked: {
    id: 'Picked',
    defaultMessage: 'Picked',
  },
  Collected: {
    id: 'Collected',
    defaultMessage: 'Collected',
  },
  NotCollected: {
    id: 'NotCollected',
    defaultMessage: 'Not collected',
  },
  Late: {
    id: 'Late',
    defaultMessage: 'Late',
  },
  SureChangeOrderStatus: {
    id: 'SureChangeOrderStatus',
    defaultMessage: 'Are you sure you want to change the order status?',
  },
  NotCancelChangeOrderStatus: {
    id: 'NotCancelChangeOrderStatus',
    defaultMessage: 'It will not be possible to change the status back to the same',
  },
  SureChangeOrderPayment: {
    id: 'SureChangeOrderPayment',
    defaultMessage: 'Are you sure you want to change your payment method?',
  },
  NoCancelChangePaymentFromCashToCard: {
    id: 'NoCancelChangePaymentFromCashToCard',
    defaultMessage: 'It will not be possible to change the payment method back from "Cash" to "Card". Proceed?',
  },
  OrderCannotCompleted: {
    id: 'OrderCannotCompleted',
    defaultMessage: 'The order cannot be completed until the buyer makes the payment online',
  },
  OrdersProgress: {
    id: 'OrdersProgress',
    defaultMessage: 'Orders in progress',
  },
  OnlyOnShift: {
    id: 'OnlyOnShift',
    defaultMessage: 'Only on shift',
  },
  HideCollectors: {
    id: 'HideCollectors',
    defaultMessage: 'Hide collectors',
  },
  Collectors: {
    id: 'Collectors',
    defaultMessage: 'Collectors',
  },
  Couriers: {
    id: 'Couriers',
    defaultMessage: 'Couriers',
  },
  Managers: {
    id: 'Managers',
    defaultMessage: 'Managers',
  },
  History: {
    id: 'History',
    defaultMessage: 'History',
  },
  HistoryNotAvailable: {
    id: 'HistoryNotAvailable',
    defaultMessage: 'History not available',
  },
  DuringAllTime: {
    id: 'DuringAllTime',
    defaultMessage: 'During all this time',
  },
  ThisMonth: {
    id: 'ThisMonth',
    defaultMessage: 'This calendar month',
  },
  LastMonth: {
    id: 'LastMonth',
    defaultMessage: 'Last calendar month',
  },
  BeforeLastMonth: {
    id: 'BeforeLastMonth',
    defaultMessage: 'In the month before last',
  },
  OverTwoMonthsAgo: {
    id: 'OverTwoMonthsAgo',
    defaultMessage: 'Over 2 months ago',
  },
  TotalOrders: {
    id: 'TotalOrders',
    defaultMessage: 'Total orders',
  },
  LastOrder: {
    id: 'LastOrder',
    defaultMessage: 'Last order',
  },
  Monitoring: {
    id: 'Monitoring',
    defaultMessage: 'Monitoring',
  },
  CollectorsAndCouriers: {
    id: 'CollectorsAndCouriers',
    defaultMessage: 'Collectors and couriers',
  },
  SlotsLoad: {
    id: 'SlotsLoad',
    defaultMessage: 'Slots load',
  },
  ShiftSchedule: {
    id: 'ShiftSchedule',
    defaultMessage: 'Shift schedule',
  },
  ShiftIndicators: {
    id: 'ShiftIndicators',
    defaultMessage: 'Shift indicators',
  },
  OrdersMap: {
    id: 'OrdersMap',
    defaultMessage: 'Orders on the map',
  },
  Back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
  Customers: {
    id: 'Customers',
    defaultMessage: 'Customers',
  },
  CustomersR: {
    id: 'CustomersR',
    defaultMessage: 'Customers',
  },
  UsersR: {
    id: 'UsersR',
    defaultMessage: 'Users',
  },
  SearchByPhoneName: {
    id: 'SearchByPhoneName',
    defaultMessage: 'Search by phone or name',
  },
  PhoneAndName: {
    id: 'PhoneAndName',
    defaultMessage: 'Phone and name',
  },
  FirstOrder: {
    id: 'FirstOrder',
    defaultMessage: 'First order',
  },
  Registration: {
    id: 'Registration',
    defaultMessage: 'Registration',
  },
  Orders: {
    id: 'Orders',
    defaultMessage: 'Orders',
  },
  DeliveryAddresses: {
    id: 'DeliveryAddresses',
    defaultMessage: 'Delivery addresses',
  },
  CustomerNoOrder: {
    id: 'CustomerNoOrder',
    defaultMessage: 'The buyer has not placed an order yet',
  },
  ShowMore: {
    id: 'ShowMore',
    defaultMessage: 'Show more',
  },
  B2B: {
    id: 'B2B',
    defaultMessage: 'B2B',
  },
  B2C: {
    id: 'B2C',
    defaultMessage: 'B2C',
  },
  AverageBill: {
    id: 'AverageBill',
    defaultMessage: 'Average bill',
  },
  From: {
    id: 'From',
    defaultMessage: 'From',
  },
  DelayedMinutes: {
    id: 'DelayedMinutes',
    defaultMessage: 'Delayed {minutes} minutes',
  },
  OrdersReviews: {
    id: 'OrdersReviews',
    defaultMessage: 'Feedback on orders',
  },
  CustomerNoReviews: {
    id: 'CustomerNoReviews',
    defaultMessage: 'The buyer has not left any feedback yet',
  },
  User: {
    id: 'User',
    defaultMessage: 'User',
  },
  OnModeration: {
    id: 'OnModeration',
    defaultMessage: 'On moderation',
  },
  ProductsModerationRules: {
    id: 'ProductsModerationRules',
    defaultMessage: 'Goods moderation rules',
  },
  SearchByProductOrEntity: {
    id: 'SearchByProductOrEntity',
    defaultMessage: 'Search by product name or legal entity',
  },
  ProductPhoto: {
    id: 'ProductPhoto',
    defaultMessage: 'Product photo',
  },
  ProductName: {
    id: 'ProductName',
    defaultMessage: 'Name of product',
  },
  Actions: {
    id: 'Actions',
    defaultMessage: 'Actions',
  },
  Approve: {
    id: 'Approve',
    defaultMessage: 'Approve',
  },
  Reject: {
    id: 'Reject',
    defaultMessage: 'Reject',
  },
  Category: {
    id: 'Category',
    defaultMessage: 'Category',
  },
  Subcategory: {
    id: 'Subcategory',
    defaultMessage: 'Subcategory',
  },
  PastValue: {
    id: 'PastValue',
    defaultMessage: 'Past value',
  },
  Description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  TaskAt: {
    id: 'TaskAt',
    defaultMessage: 'Task #{num} dated {date}',
  },
  RejectionReason: {
    id: 'RejectionReason',
    defaultMessage: 'Rejection reason',
  },
  Approved: {
    id: 'Approved',
    defaultMessage: 'Approved',
  },
  Rejected: {
    id: 'Rejected',
    defaultMessage: 'Rejected',
  },
  New: {
    id: 'New',
    defaultMessage: 'New',
  },
  Repeated: {
    id: 'Repeated',
    defaultMessage: 'Repeated',
  },
  Pcs: {
    id: 'Pcs',
    defaultMessage: 'pcs',
  },
  Kg: {
    id: 'Kg',
    defaultMessage: 'kg',
  },
  Gram: {
    id: 'Gram',
    defaultMessage: 'G',
  },
  Litre: {
    id: 'Litre',
    defaultMessage: 'l',
  },
  Millilitre: {
    id: 'Millilitre',
    defaultMessage: 'ml',
  },
  UnitMeasureShort: {
    id: 'UnitMeasureShort',
    defaultMessage: 'Unit',
  },
  SaleBy: {
    id: 'SaleBy',
    defaultMessage: 'Sale by ({value})',
  },
  WeightOfOnePiece: {
    id: 'WeightOfOnePiece',
    defaultMessage: 'Weight of one piece',
  },
  PurchaseStep: {
    id: 'PurchaseStep',
    defaultMessage: 'Purchase step',
  },
  PricePer: {
    id: 'PricePer',
    defaultMessage: 'Price per ({value})',
  },
  EntityShort: {
    id: 'EntityShort',
    defaultMessage: 'Entity',
  },
  StoreNameAndNumber: {
    id: 'StoreNameAndNumber',
    defaultMessage: 'Store name and number',
  },
  MarketingTags: {
    id: 'MarketingTags',
    defaultMessage: 'Marketing tags',
  },
  SelectMarket: {
    id: 'SelectMarket',
    defaultMessage: 'Select market',
  },
  Add: {
    id: 'Add',
    defaultMessage: 'Add',
  },
  SureWantRemoveTag: {
    id: 'SureWantRemoveTag',
    defaultMessage: 'Are you sure you want to remove the tag?',
  },
  Tag: {
    id: 'Tag',
    defaultMessage: 'Tag',
  },
  ChangeProductTag: {
    id: 'ChangeProductTag',
    defaultMessage: 'Change product tag',
  },
  ProductChange: {
    id: 'ProductChange',
    defaultMessage: 'Product change',
  },
  AddingProduct: {
    id: 'AddingProduct',
    defaultMessage: 'Adding a product',
  },
  Sellers: {
    id: 'Sellers',
    defaultMessage: 'Sellers',
  },
  Markets: {
    id: 'Markets',
    defaultMessage: 'Markets',
  },
  Notifications: {
    id: 'Notifications',
    defaultMessage: 'Notifications',
  },
  Analytics: {
    id: 'Analytics',
    defaultMessage: 'Analytics',
  },
  Tags: {
    id: 'Tags',
    defaultMessage: 'Tags',
  },
  Moderation: {
    id: 'Moderation',
    defaultMessage: 'Moderation',
  },
  RegisterSeller: {
    id: 'RegisterSeller',
    defaultMessage: 'Register seller',
  },
  FullRegistrationSeller: {
    id: 'FullRegistrationSeller',
    defaultMessage: 'Full registration of the seller (IP or LLC)',
  },
  InProcessRegistration: {
    id: 'InProcessRegistration',
    defaultMessage: 'In the process of registration',
  },
  ListTemplatesInProgress: {
    id: 'ListTemplatesInProgress',
    defaultMessage: 'List of templates in progress',
  },
  ShowcasesSellers: {
    id: 'ShowcasesSellers',
    defaultMessage: 'Showcases of sellers',
  },
  InformationSellersProducts: {
    id: 'InformationSellersProducts',
    defaultMessage: 'Information about all sellers and their products',
  },
  CopyProducts: {
    id: 'CopyProducts',
    defaultMessage: 'Copy Products',
  },
  CopyingAllProductsInfo: {
    id: 'CopyingAllProductsInfo',
    defaultMessage:
      'Copying all products from one seller to another' +
      ' (if the goods are transferred to the "General" seller, then the "place" is filled from the profile)',
  },
  SystemVendors: {
    id: 'SystemVendors',
    defaultMessage: 'System vendors',
  },
  SellersInProcessRegistration: {
    id: 'SellersInProcessRegistration',
    defaultMessage: 'Sellers in the process of registration',
  },
  SureDeleteSellerProfile: {
    id: 'SureDeleteSellerProfile',
    defaultMessage: 'Are you sure you want to delete your seller profile {seller}?',
  },
  Confirm: {
    id: 'Confirm',
    defaultMessage: 'Confirm',
  },
  ShowcaseSeller: {
    id: 'ShowcaseSeller',
    defaultMessage: 'Showcase of the seller',
  },
  Missing: {
    id: 'Missing',
    defaultMessage: 'Missing',
  },
  SureDeleteProduct: {
    id: 'SureDeleteProduct',
    defaultMessage: 'Are you sure you want to delete the item?',
  },
  Hidden: {
    id: 'Hidden',
    defaultMessage: 'Hidden',
  },
  All: {
    id: 'All',
    defaultMessage: 'All',
  },
  Active: {
    id: 'Active',
    defaultMessage: 'Active',
  },
  HiddenS: {
    id: 'HiddenS',
    defaultMessage: 'Hidden',
  },
  SellerPlace: {
    id: 'SellerPlace',
    defaultMessage: 'Seller\'s place',
  },
  AddNewProduct: {
    id: 'AddNewProduct',
    defaultMessage: 'Add new item',
  },
  EditingSellerShowcase: {
    id: 'EditingSellerShowcase',
    defaultMessage: 'Editing the seller\'s showcase',
  },
  Polygons: {
    id: 'Polygons',
    defaultMessage: 'Polygons',
  },
  Slots: {
    id: 'Slots',
    defaultMessage: 'Slots',
  },
  Assortment: {
    id: 'Assortment',
    defaultMessage: 'Assortment',
  },
  Catalog: {
    id: 'Catalog',
    defaultMessage: 'Catalog',
  },
  EditPolygons: {
    id: 'EditPolygons',
    defaultMessage: 'Edit polygons',
  },
  OpeningHours: {
    id: 'OpeningHours',
    defaultMessage: 'Opening hours',
  },
  DeliveryHours: {
    id: 'DeliveryHours',
    defaultMessage: 'Delivery hours',
  },
  MonSunFromTo: {
    id: 'MonSunFromTo',
    defaultMessage: 'Mon-Sun from {startTime} to {endTime}',
  },
  SomeIntervalsNotSaved: {
    id: 'SomeIntervalsNotSaved',
    defaultMessage: 'Some intervals were not saved',
  },
  CannotCreateIntervalsOverlapAnother: {
    id: 'CannotCreateIntervalsOverlapAnother',
    defaultMessage: 'You cannot create intervals that overlap one another.',
  },
  DeliverySlots: {
    id: 'DeliverySlots',
    defaultMessage: 'Delivery slots',
  },
  DeliverySlotsNotSet: {
    id: 'DeliverySlotsNotSet',
    defaultMessage: 'Delivery slots not set yet',
  },
  HereConfigureDeliverySlots: {
    id: 'HereConfigureDeliverySlots',
    defaultMessage: 'Here you can add and configure delivery slots',
  },
  AddSlots: {
    id: 'AddSlots',
    defaultMessage: 'Add slots',
  },
  AddSlot: {
    id: 'AddSlot',
    defaultMessage: 'Add slot',
  },
  BestFromMarket: {
    id: 'BestFromMarket',
    defaultMessage: 'Best from the market',
  },
  Stalls: {
    id: 'Stalls',
    defaultMessage: 'Stalls',
  },
  SearchByProducts: {
    id: 'SearchByProducts',
    defaultMessage: 'Search by products',
  },
  SearchBySellers: {
    id: 'SearchBySellers',
    defaultMessage: 'Search by sellers',
  },
  ShopsNotFound: {
    id: 'ShopsNotFound',
    defaultMessage: 'Shops not found',
  },
  ProductsNotFound: {
    id: 'ProductsNotFound',
    defaultMessage: 'Products not found',
  },
  StallsSearchEmpty: {
    id: 'StallsSearchEmpty',
    defaultMessage:
      'Check the request. Perhaps there is an error in it. Or look for goods at the best from the market.',
  },
  BestFromMarketSearchEmpty: {
    id: 'BestFromMarketSearchEmpty',
    defaultMessage: 'Check the request. Perhaps there is an error in it. Or look for goods in shops.',
  },
  AssortmentNotCreated: {
    id: 'AssortmentNotCreated',
    defaultMessage: 'Assortment not created yet',
  },
  HereCreateCategoriesSubcategories: {
    id: 'HereCreateCategoriesSubcategories',
    defaultMessage: 'Here you can create categories and subcategories for the market',
  },
  CreateAssortment: {
    id: 'CreateAssortment',
    defaultMessage: 'Create assortment',
  },
  CategoriesAndSubcategories: {
    id: 'CategoriesAndSubcategories',
    defaultMessage: 'Categories and subcategories',
  },
  Edit: {
    id: 'Edit',
    defaultMessage: 'Edit',
  },
  Categories: {
    id: 'Categories',
    defaultMessage: 'Categories',
  },
  Subcategories: {
    id: 'Subcategories',
    defaultMessage: 'Subcategories',
  },
  AddSubcategory: {
    id: 'AddSubcategory',
    defaultMessage: 'Add subcategory',
  },
  FinishEditing: {
    id: 'FinishEditing',
    defaultMessage: 'Finish editing',
  },
  SubcategoriesR: {
    id: 'SubcategoriesR',
    defaultMessage: 'Subcategories',
  },
  GoodsR: {
    id: 'GoodsR',
    defaultMessage: 'Goods',
  },
  AddedProducts: {
    id: 'AddedProducts',
    defaultMessage: 'Added products',
  },
  CategoryHidden: {
    id: 'CategoryHidden',
    defaultMessage: 'The category is currently hidden in the app',
  },
  CategoryHiddenInfo: {
    id: 'CategoryHiddenInfo',
    defaultMessage: 'Users will not see it in categories',
  },
  SubcategoryHidden: {
    id: 'SubcategoryHidden',
    defaultMessage: 'The subcategory is currently hidden in the app',
  },
  SubcategoryHiddenInfo: {
    id: 'SubcategoryHiddenInfo',
    defaultMessage: 'Users will not see her products in the category',
  },
  Other: {
    id: 'Other',
    defaultMessage: 'Other',
  },
  UpdateProductRatings: {
    id: 'UpdateProductRatings',
    defaultMessage: 'Update product ratings',
  },
  SureUpdateRatingsInSubcategory: {
    id: 'SureUpdateRatingsInSubcategory',
    defaultMessage: 'Are you sure you want to update the ratings of all products in a subcategory?',
  },
  UpdateRatingsWarning: {
    id: 'UpdateRatingsWarning',
    defaultMessage:
      'After the update, all products in the subcategory will have a real rating,' +
      ' which depends on the frequency of purchase of the product.' +
      ' Accordingly, their order of issuance in the application will change.',
  },
  Refresh: {
    id: 'Refresh',
    defaultMessage: 'Refresh',
  },
  AddCategory: {
    id: 'AddCategory',
    defaultMessage: 'Add category',
  },
  CategoryName: {
    id: 'CategoryName',
    defaultMessage: 'Name of category',
  },
  CategoryCurrentlyHidden: {
    id: 'CategoryCurrentlyHidden',
    defaultMessage: 'The category is currently hidden in the app',
  },
  CategoryIsVisible: {
    id: 'CategoryIsVisible',
    defaultMessage: 'The category is now visible in the app',
  },
  ClickCategoryVisible: {
    id: 'ClickCategoryVisible',
    defaultMessage: 'Click to make category visible',
  },
  ClickHideCategory: {
    id: 'ClickHideCategory',
    defaultMessage: 'Click to hide category',
  },
  OnlyCategoryCannotHidden: {
    id: 'OnlyCategoryCannotHidden',
    defaultMessage: 'The only category in the assortment cannot be hidden',
  },
  OnlyCategoryCannotDeleted: {
    id: 'OnlyCategoryCannotDeleted',
    defaultMessage: 'The only category in the assortment cannot be deleted',
  },
  RemovingCategory: {
    id: 'RemovingCategory',
    defaultMessage: 'Removing the «{category}» category',
  },
  DeletingCategoryWarning: {
    id: 'DeletingCategoryWarning',
    defaultMessage:
      'Deleting a category will delete all subcategories in that category.' + ' This action cannot be undone. Delete?',
  },
  ImageEditing: {
    id: 'ImageEditing',
    defaultMessage: 'Image editing',
  },
  SubcategoryName: {
    id: 'SubcategoryName',
    defaultMessage: 'Subcategory name',
  },
  SubcategoryCurrentlyHidden: {
    id: 'SubcategoryCurrentlyHidden',
    defaultMessage: 'The subcategory is currently hidden in the application.',
  },
  SubcategoryIsVisible: {
    id: 'SubcategoryIsVisible',
    defaultMessage: 'The subcategory is now visible in the application.',
  },
  ClickHideSubcategory: {
    id: 'ClickHideSubcategory',
    defaultMessage: 'Click to hide subcategory',
  },
  ClickSubcategoryVisible: {
    id: 'ClickSubcategoryVisible',
    defaultMessage: 'Click to make a subcategory visible',
  },
  MarketName: {
    id: 'MarketName',
    defaultMessage: 'Market name',
  },
  TagName: {
    id: 'TagName',
    defaultMessage: 'Tag name',
  },
  SignIn: {
    id: 'SignIn',
    defaultMessage: 'Sign in',
  },
  Login: {
    id: 'Login',
    defaultMessage: 'Login',
  },
  EnterLogin: {
    id: 'EnterLogin',
    defaultMessage: 'Enter login',
  },
  Password: {
    id: 'Password',
    defaultMessage: 'Password',
  },
  EnterPassword: {
    id: 'EnterPassword',
    defaultMessage: 'Enter password',
  },
  Error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  FieldRequired: {
    id: 'FieldRequired',
    defaultMessage: 'This field is required',
  },
  ForSpecificCustomers: {
    id: 'ForSpecificCustomers',
    defaultMessage: 'For specific customers',
  },
  SureRemoveNotification: {
    id: 'SureRemoveNotification',
    defaultMessage: 'Are you sure you want to remove the notification "{title}"?',
  },
  Notification: {
    id: 'Notification',
    defaultMessage: 'Notification',
  },
  Header: {
    id: 'Header',
    defaultMessage: 'Header',
  },
  Text: {
    id: 'Text',
    defaultMessage: 'Text',
  },
  DispatchTime: {
    id: 'DispatchTime',
    defaultMessage: 'Dispatch time',
  },
  Type: {
    id: 'Type',
    defaultMessage: 'Type',
  },
  Monday: {
    id: 'Monday',
    defaultMessage: 'Monday',
  },
  Tuesday: {
    id: 'Tuesday',
    defaultMessage: 'Tuesday',
  },
  Wednesday: {
    id: 'Wednesday',
    defaultMessage: 'Wednesday',
  },
  Thursday: {
    id: 'Thursday',
    defaultMessage: 'Thursday',
  },
  Friday: {
    id: 'Friday',
    defaultMessage: 'Friday',
  },
  Saturday: {
    id: 'Saturday',
    defaultMessage: 'Saturday',
  },
  Sunday: {
    id: 'Sunday',
    defaultMessage: 'Sunday',
  },
  DaysOfWeek: {
    id: 'DaysOfWeek',
    defaultMessage: 'Days of the week',
  },
  Periodicity: {
    id: 'Periodicity',
    defaultMessage: 'Periodicity',
  },
  StartingFromCurrent: {
    id: 'StartingFromCurrent',
    defaultMessage: 'Starting from the current',
  },
  HowManyWeeksRepeat: {
    id: 'HowManyWeeksRepeat',
    defaultMessage: 'How many weeks will it repeat',
  },
  UserGroup: {
    id: 'UserGroup',
    defaultMessage: 'User group',
  },
  EnterClientNumberOrName: {
    id: 'EnterClientNumberOrName',
    defaultMessage: 'Enter client number or name',
  },
  NotLessAndNotMoreCharacters: {
    id: 'NotLessAndNotMoreCharacters',
    defaultMessage: 'Not less than {less} and not more than {more} characters',
  },
  IncorrectFormat: {
    id: 'IncorrectFormat',
    defaultMessage: 'Incorrect format',
  },
  SentNotifications: {
    id: 'SentNotifications',
    defaultMessage: 'Sent notifications',
  },
  Quantity: {
    id: 'Quantity',
    defaultMessage: 'Quantity',
  },
  DepartureDate: {
    id: 'DepartureDate',
    defaultMessage: 'Departure date',
  },
  Single: {
    id: 'Single',
    defaultMessage: 'Single',
  },
  Scheduled: {
    id: 'Scheduled',
    defaultMessage: 'Scheduled',
  },
  AllUsers: {
    id: 'AllUsers',
    defaultMessage: 'All users',
  },
  RegisteredUsers: {
    id: 'RegisteredUsers',
    defaultMessage: 'Registered users',
  },
  UnregisteredUsers: {
    id: 'UnregisteredUsers',
    defaultMessage: 'Unregistered users',
  },
  UserType: {
    id: 'UserType',
    defaultMessage: 'User type',
  },
  SignOut: {
    id: 'SignOut',
    defaultMessage: 'Sign out?',
  },
  Yes: {
    id: 'Yes',
    defaultMessage: 'Yes',
  },
  Admin: {
    id: 'Admin',
    defaultMessage: 'Admin',
  },
  Manager: {
    id: 'Manager',
    defaultMessage: 'Manager',
  },
  Operator: {
    id: 'Operator',
    defaultMessage: 'Operator',
  },
  SelectTags: {
    id: 'SelectTags',
    defaultMessage: 'Select tags',
  },
  ChangeHowReceive: {
    id: 'ChangeHowReceive',
    defaultMessage: 'Change how you receive',
  },
  DeliveryAddress: {
    id: 'DeliveryAddress',
    defaultMessage: 'Delivery address',
  },
  EnterAddress: {
    id: 'EnterAddress',
    defaultMessage: 'Enter address',
  },
  EnterDeliveryDate: {
    id: 'EnterDeliveryDate',
    defaultMessage: 'Please enter a delivery date',
  },
  SpecifyDeliveryTime: {
    id: 'SpecifyDeliveryTime',
    defaultMessage: 'Specify delivery time',
  },
  Apply: {
    id: 'Apply',
    defaultMessage: 'Apply',
  },
  Reset: {
    id: 'Reset',
    defaultMessage: 'Reset',
  },
  Delete: {
    id: 'Delete',
    defaultMessage: 'Delete',
  },
  QuantityGoods: {
    id: 'QuantityGoods',
    defaultMessage: 'Quantity of goods',
  },
  EnterTitle: {
    id: 'EnterTitle',
    defaultMessage: 'Enter the title',
  },
  EditTag: {
    id: 'EditTag',
    defaultMessage: 'Edit tag',
  },
  CreateTag: {
    id: 'CreateTag',
    defaultMessage: 'Create tag',
  },
  Color: {
    id: 'Color',
    defaultMessage: 'Color',
  },
  Priority: {
    id: 'Priority',
    defaultMessage: 'Priority',
  },
  Image: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  Download: {
    id: 'Download',
    defaultMessage: 'Download',
  },
  NoMoreThanCharacters: {
    id: 'NoMoreThanCharacters',
    defaultMessage: 'No more than {length} characters',
  },
  StallsSellers: {
    id: 'StallsSellers',
    defaultMessage: 'Stalls (sellers)',
  },
  ProductLinks: {
    id: 'ProductLinks',
    defaultMessage: 'Product links',
  },
  ThroughCategories: {
    id: 'ThroughCategories',
    defaultMessage: 'Through categories',
  },
  ThroughSeller: {
    id: 'ThroughSeller',
    defaultMessage: 'Through the seller',
  },
  Country: {
    id: 'Country',
    defaultMessage: 'Country',
  },
  VendorCode: {
    id: 'VendorCode',
    defaultMessage: 'Vendor code',
  },
  Rating: {
    id: 'Rating',
    defaultMessage: 'Rating',
  },
  Unit: {
    id: 'Unit',
    defaultMessage: 'Unit',
  },
  WeightOnePieceGrams: {
    id: 'WeightOnePieceGrams',
    defaultMessage: 'Weight of one piece in grams',
  },
  SellBy: {
    id: 'SellBy',
    defaultMessage: 'Sell by ({unit})',
  },
  DiscountPriceFor: {
    id: 'DiscountPriceFor',
    defaultMessage: 'Discount price for ({unit})',
  },
  OriginalPrice: {
    id: 'OriginalPrice',
    defaultMessage: 'Original price',
  },
  UpdatePriceWithCommission: {
    id: 'UpdatePriceWithCommission',
    defaultMessage: 'Update price with commission ({commission}%)',
  },
  PriceWithoutDiscountFor: {
    id: 'PriceWithoutDiscountFor',
    defaultMessage: 'Price without discount for ({unit})',
  },
  CreateCopy: {
    id: 'CreateCopy',
    defaultMessage: 'Create a copy',
  },
  ValueMustGreaterThan: {
    id: 'ValueMustGreaterThan',
    defaultMessage: 'Value must be greater than {value}',
  },
  ValueMustNotExceedCharacters: {
    id: 'ValueMustNotExceedCharacters',
    defaultMessage: 'The value must not exceed {limit} characters',
  },
  ValueMustNumberGreaterThan: {
    id: 'ValueMustNumberGreaterThan',
    defaultMessage: 'Value must be a number and greater than {value}',
  },
  MustAddImage: {
    id: 'MustAddImage',
    defaultMessage: 'Add image',
  },
  Added: {
    id: 'Added',
    defaultMessage: 'Added',
  },
  ShopName: {
    id: 'ShopName',
    defaultMessage: 'Shop name',
  },
  SellerNameNickname: {
    id: 'SellerNameNickname',
    defaultMessage: 'Seller name/Nickname',
  },
  PhotoYourShop: {
    id: 'PhotoYourShop',
    defaultMessage: 'Photo of your shop',
  },
  SellerPhoto: {
    id: 'SellerPhoto',
    defaultMessage: 'Seller photo',
  },
  AssignOrder: {
    id: 'AssignOrder',
    defaultMessage: 'Assign order',
  },
  FieldMustNotEmpty: {
    id: 'FieldMustNotEmpty',
    defaultMessage: 'The field must not be empty',
  },
  Weight: {
    id: 'Weight',
    defaultMessage: 'Weight',
  },
  ForAmount: {
    id: 'ForAmount',
    defaultMessage: 'ForAmount',
  },
  InAssembly: {
    id: 'InAssembly',
    defaultMessage: 'In assembly',
  },
  AtCourier: {
    id: 'AtCourier',
    defaultMessage: 'At courier',
  },
  OnDelivery: {
    id: 'OnDelivery',
    defaultMessage: 'On delivery',
  },
  Statuses: {
    id: 'Statuses',
    defaultMessage: 'Statuses',
  },
  Canceled: {
    id: 'Canceled',
    defaultMessage: 'Canceled',
  },
  Period: {
    id: 'Period',
    defaultMessage: 'Period',
  },
  Users: {
    id: 'Users',
    defaultMessage: 'Users',
  },
  NewRegisteredUsers: {
    id: 'NewRegisteredUsers',
    defaultMessage: 'New registered users',
  },
  DeliveredMany: {
    id: 'DeliveredMany',
    defaultMessage: 'Delivered',
  },
  CanceledMany: {
    id: 'CanceledMany',
    defaultMessage: 'Canceled',
  },
  NumberCustomers: {
    id: 'NumberCustomers',
    defaultMessage: 'Number of customers',
  },
  NumberCustomersLeastOneOrder: {
    id: 'NumberCustomersLeastOneOrder',
    defaultMessage: 'Number of customers who made at least 1 order (delivered)',
  },
  OfTheseNumberFirst: {
    id: 'OfTheseNumberFirst',
    defaultMessage: 'Of these, number of first',
  },
  NumberCustomersFirstOrder: {
    id: 'NumberCustomersFirstOrder',
    defaultMessage: 'Number of customers who made an order (delivered) for the first time',
  },
  NumberOrdersFirstMonth: {
    id: 'NumberOrdersFirstMonth',
    defaultMessage: 'Number of user orders (first month)',
  },
  AverageOrdersFirstMonth: {
    id: 'AverageOrdersFirstMonth',
    defaultMessage:
      'Average number of orders in the first month of use after registration' +
      ' for users created since the start of the selected interval',
  },
  NumberOrdersLastMonth: {
    id: 'NumberOrdersLastMonth',
    defaultMessage: 'Number of user orders (last month)',
  },
  AverageOrdersLastMonth: {
    id: 'AverageOrdersLastMonth',
    defaultMessage:
      'The average number of orders for the last month of use after registration' +
      ' for users created before the end of the selected interval',
  },
  Turnover: {
    id: 'Turnover',
    defaultMessage: 'Turnover',
  },
  CommissionPlatform: {
    id: 'CommissionPlatform',
    defaultMessage: 'Commission Wow! Food',
  },
  TotalWeight: {
    id: 'TotalWeight',
    defaultMessage: 'Total weight',
  },
  Total: {
    id: 'Total',
    defaultMessage: 'Total',
  },
  TotalStats: {
    id: 'TotalStats',
    defaultMessage: 'Total stats',
  },
  OrderCard: {
    id: 'OrderCard',
    defaultMessage: 'Order card',
  },
  ItemRejection: {
    id: 'ItemRejection',
    defaultMessage: 'Item rejection',
  },
  SpecifyReason: {
    id: 'SpecifyReason',
    defaultMessage: 'Specify the reason',
  },
  DeliverySlot: {
    id: 'DeliverySlot',
    defaultMessage: 'Delivery slot',
  },
  CustomerCard: {
    id: 'CustomerCard',
    defaultMessage: 'Customer card',
  },
  ItemsReport: {
    id: 'ItemsReport',
    defaultMessage: 'Items report',
  },
  Polygon: {
    id: 'Polygon',
    defaultMessage: 'Polygon',
  },
  CopyingProducts: {
    id: 'CopyingProducts',
    defaultMessage: 'Copying products',
  },
  SelectSellers: {
    id: 'SelectSellers',
    defaultMessage: 'Select sellers',
  },
  SellerCopyFrom: {
    id: 'SellerCopyFrom',
    defaultMessage: 'From which seller to copy',
  },
  SellerCopyTo: {
    id: 'SellerCopyTo',
    defaultMessage: 'Which seller to copy',
  },
  CopiedSuccessfully: {
    id: 'CopiedSuccessfully',
    defaultMessage: 'All items have been copied successfully',
  },
  Completed: {
    id: 'Completed',
    defaultMessage: 'Completed',
  },
  PolygonsNotSet: {
    id: 'PolygonsNotSet',
    defaultMessage: 'Delivery polygons not set yet',
  },
  ConfigurePolygons: {
    id: 'ConfigurePolygons',
    defaultMessage: 'Here you can add and configure delivery polygons',
  },
  AddPolygons: {
    id: 'AddPolygons',
    defaultMessage: 'Add polygons',
  },
  AddPolygon: {
    id: 'AddPolygon',
    defaultMessage: 'Add polygon',
  },
  PolygonDisabledToday: {
    id: 'PolygonDisabledToday',
    defaultMessage:
      'Polygon is disabled for today ({time}).' +
      ' This means that users within it cannot order in-app delivery for today.',
  },
  PolygonDisabledUnlimited: {
    id: 'PolygonDisabledUnlimited',
    defaultMessage:
      'The polygon is turned off indefinitely.' + ' This means that users within it cannot order delivery in the app.',
  },
  AvailableOrderTime: {
    id: 'AvailableOrderTime',
    defaultMessage: 'Available order time',
  },
  PriceDelivery: {
    id: 'PriceDelivery',
    defaultMessage: 'Delivery cost',
  },
  MinimumOrder: {
    id: 'MinimumOrder',
    defaultMessage: 'Minimum order',
  },
  AsSoonAsPossible: {
    id: 'AsSoonAsPossible',
    defaultMessage: 'As soon as possible',
  },
  BySlotNearest: {
    id: 'BySlotNearest',
    defaultMessage: 'By slot (nearest slot)',
  },
  BySlotsThroughOne: {
    id: 'BySlotsThroughOne',
    defaultMessage: 'By slots (through one slot)',
  },
  BySlotsThroughTwo: {
    id: 'BySlotsThroughTwo',
    defaultMessage: 'By slots (through two slots)',
  },
  AllDay: {
    id: 'AllDay',
    defaultMessage: 'All day',
  },
  AddAvailableTime: {
    id: 'AddAvailableTime',
    defaultMessage: 'Add available time',
  },
  Clear: {
    id: 'Clear',
    defaultMessage: 'Clear',
  },
  PolygonShutdown: {
    id: 'PolygonShutdown',
    defaultMessage: 'Polygon shutdown',
  },
  DisableDeliverySlotsToday: {
    id: 'DisableDeliverySlotsToday',
    defaultMessage: 'Disable delivery slots for today ({time})' + ' Users will only be able to order for tomorrow',
  },
  DisableDeliverySlotsUnlimited: {
    id: 'DisableDeliverySlotsUnlimited',
    defaultMessage: 'Turn off the delivery range for an unlimited time',
  },
  SwitchOff: {
    id: 'SwitchOff',
    defaultMessage: 'Switch off',
  },
  FromWith: {
    id: 'FromWith',
    defaultMessage: 'From',
  },
  Before: {
    id: 'Before',
    defaultMessage: 'Before',
  },
  SlotOverloaded: {
    id: 'SlotOverloaded',
    defaultMessage: 'The slot is overloaded. Shut it down or turn it up',
  },
  CloseSlot: {
    id: 'CloseSlot',
    defaultMessage: 'Close slot',
  },
  OpenSlot: {
    id: 'OpenSlot',
    defaultMessage: 'Open slot',
  },
  SlotLoad: {
    id: 'SlotLoad',
    defaultMessage: 'Slot load',
  },
  FactPlan: {
    id: 'FactPlan',
    defaultMessage: 'Fact / plan',
  },
  CouriersSlot: {
    id: 'CouriersSlot',
    defaultMessage: 'Couriers on the slot',
  },
  PickersSlot: {
    id: 'PickersSlot',
    defaultMessage: 'Pickers on the slot',
  },
  ManagersSlot: {
    id: 'ManagersSlot',
    defaultMessage: 'Managers on the slot',
  },
  DownloadIndex: {
    id: 'DownloadIndex',
    defaultMessage: 'Download index',
  },
  CallMe: {
    id: 'CallMe',
    defaultMessage: 'Call me',
  },
  NotCallMe: {
    id: 'NotCallMe',
    defaultMessage: 'Do not call me',
  },
  IfProductOutOfStock: {
    id: 'IfProductOutOfStock',
    defaultMessage: 'If the product is out of stock or defective',
  },
  PickReplacement: {
    id: 'PickReplacement',
    defaultMessage: 'Pick a replacement',
  },
  DeleteProduct: {
    id: 'DeleteProduct',
    defaultMessage: 'Delete product',
  },
  IfWeightItemChanged: {
    id: 'IfWeightItemChanged',
    defaultMessage: 'If the weight of the item has changed',
  },
  RemoveItemIfWeightChanges: {
    id: 'RemoveItemIfWeightChanges',
    defaultMessage: 'Remove an item if its weight changes by more than 10%',
  },
  ChangeItemWeight: {
    id: 'ChangeItemWeight',
    defaultMessage: 'Change the item\'s weight',
  },
  AboutProductReplacement: {
    id: 'AboutProductReplacement',
    defaultMessage: 'About product replacement',
  },
  AboutDeletingItem: {
    id: 'AboutDeletingItem',
    defaultMessage: 'About deleting an item',
  },
  IfWeightItemChanges: {
    id: 'IfWeightItemChanges',
    defaultMessage: 'If the weight of the item changes',
  },
  IfNotAnswer: {
    id: 'IfNotAnswer',
    defaultMessage: 'If I don\'t answer',
  },
  DuringDay: {
    id: 'DuringDay',
    defaultMessage: 'During the day',
  },
  PayAttention: {
    id: 'PayAttention',
    defaultMessage: 'Pay attention',
  },
  Mon: {
    id: 'Mon',
    defaultMessage: 'Mon',
  },
  Tue: {
    id: 'Tue',
    defaultMessage: 'Tue',
  },
  Wed: {
    id: 'Wed',
    defaultMessage: 'Wed',
  },
  Thu: {
    id: 'Thu',
    defaultMessage: 'Thu',
  },
  Fri: {
    id: 'Fri',
    defaultMessage: 'Fri',
  },
  Sat: {
    id: 'Sat',
    defaultMessage: 'Sat',
  },
  Sun: {
    id: 'Sun',
    defaultMessage: 'Sun',
  },
  Daily: {
    id: 'Daily',
    defaultMessage: 'Daily',
  },
  ByDayWeek: {
    id: 'ByDayWeek',
    defaultMessage: 'By day of the week',
  },
  Once: {
    id: 'Once',
    defaultMessage: 'Once',
  },
  Weekly: {
    id: 'Weekly',
    defaultMessage: 'Weekly',
  },
  ToAllCustomers: {
    id: 'ToAllCustomers',
    defaultMessage: 'To all customers',
  },
  ToRegisteredCustomers: {
    id: 'ToRegisteredCustomers',
    defaultMessage: 'Registered customers',
  },
  ToNotRegisteredCustomers: {
    id: 'ToNotRegisteredCustomers',
    defaultMessage: 'Not registered customers',
  },
  EveryWeeks: {
    id: 'EveryWeeks',
    defaultMessage: 'Every {num} weeks',
  },
  NotShift: {
    id: 'NotShift',
    defaultMessage: 'Not shift',
  },
  OnShift: {
    id: 'OnShift',
    defaultMessage: 'On shift',
  },
  DeliversOrder: {
    id: 'DeliversOrder',
    defaultMessage: 'Delivers order',
  },
  Free: {
    id: 'Free',
    defaultMessage: 'Free',
  },
  CollectsOrder: {
    id: 'CollectsOrder',
    defaultMessage: 'Collects an order',
  },
  ProductCreationError: {
    id: 'ProductCreationError',
    defaultMessage: 'Product creation error',
  },
  ProductUpdateError: {
    id: 'ProductUpdateError',
    defaultMessage: 'Product update error',
  },
  ProductDeletionError: {
    id: 'ProductDeletionError',
    defaultMessage: 'Product deletion error',
  },
  ProductCopyError: {
    id: 'ProductCopyError',
    defaultMessage: 'Product copy error',
  },
  ProductApproved: {
    id: 'ProductApproved',
    defaultMessage: 'Product approved and moved to Archive',
  },
  ProductRejected: {
    id: 'ProductRejected',
    defaultMessage: 'Product rejected and moved to Archive',
  },
  Successfully: {
    id: 'Successfully',
    defaultMessage: 'Successfully',
  },
  ModerationUpdateError: {
    id: 'ModerationUpdateError',
    defaultMessage: 'Product moderation status update error',
  },
  General: {
    id: 'General',
    defaultMessage: 'General',
  },
  CustomerOrders: {
    id: 'CustomerOrders',
    defaultMessage: 'Customer orders',
  },
  Products: {
    id: 'Products',
    defaultMessage: 'Products',
  },
  ProductsChain: {
    id: 'ProductsChain',
    defaultMessage: 'Product links',
  },
  DefaultProductGroup: {
    id: 'DefaultProductGroup',
    defaultMessage: 'Default product group',
  },
  DefaultProductGroupInfo: {
    id: 'DefaultProductGroupInfo',
    defaultMessage: 'Displayed for products if links are not configured for them',
  },
  CreateGroup: {
    id: 'CreateGroup',
    defaultMessage: 'Create group',
  },
  SpecificProductsLinks: {
    id: 'SpecificProductsLinks',
    defaultMessage: 'Links to specific products',
  },
  SpecificProductsLinksInfo: {
    id: 'SpecificProductsLinksInfo',
    defaultMessage: 'Here you can add links between products',
  },
  AddLinks: {
    id: 'AddLinks',
    defaultMessage: 'Add links',
  },
  ProductsSelected: {
    id: 'ProductsSelected',
    defaultMessage: 'Products selected',
  },
  ResetAllProducts: {
    id: 'ResetAllProducts',
    defaultMessage: 'Reset all products',
  },
  TieUp: {
    id: 'TieUp',
    defaultMessage: 'Tie up',
  },
  Change: {
    id: 'Change',
    defaultMessage: 'Change',
  },
  Group: {
    id: 'Group',
    defaultMessage: 'Group',
  },
  RemovingGroup: {
    id: 'RemovingGroup',
    defaultMessage: 'Are you sure you want to delete the default group?',
  },
  RemovingGroupWarning: {
    id: 'RemovingGroupWarning',
    defaultMessage: 'This action cannot be undone',
  },
  ItemToTie: {
    id: 'ItemToTie',
    defaultMessage: 'Item to tie',
  },
  WhatTie: {
    id: 'WhatTie',
    defaultMessage: 'What to tie',
  },
  WhatToConnectWith: {
    id: 'WhatToConnectWith',
    defaultMessage: 'What to connect with',
  },
  Select: {
    id: 'Select',
    defaultMessage: 'Select',
  },
  Link: {
    id: 'Link',
    defaultMessage: 'Link',
  },
  Hide: {
    id: 'Hide',
    defaultMessage: 'Hide',
  },
  HideOff: {
    id: 'HideOff',
    defaultMessage: 'Make visible',
  },
  RemovingLink: {
    id: 'RemovingLink',
    defaultMessage: 'Are you sure you want to delete this product link?',
  },
  INN: {
    id: 'INN',
    defaultMessage: 'TIN',
  },
  Selected: {
    id: 'Selected',
    defaultMessage: 'Selected',
  },
  SelectAnother: {
    id: 'SelectAnother',
    defaultMessage: 'Select another',
  },
  InWork: {
    id: 'InWork',
    defaultMessage: 'In work',
  },
  CourierRoutes: {
    id: 'CourierRoutes',
    defaultMessage: 'Courier routes',
  },
  Route: {
    id: 'Route',
    defaultMessage: 'Route',
  },
  ViewRoute: {
    id: 'ViewRoute',
    defaultMessage: 'View route',
  },
  ChangeRoute: {
    id: 'ChangeRoute',
    defaultMessage: 'Change route',
  },
  OrderStatus: {
    id: 'OrderStatus',
    defaultMessage: 'Order status',
  },
  Set: {
    id: 'Set',
    defaultMessage: 'Set',
  },
  NotSet: {
    id: 'NotSet',
    defaultMessage: 'Not set',
  },
  SetRoute: {
    id: 'SetRoute',
    defaultMessage: 'Set route',
  },
  Order: {
    id: 'Order',
    defaultMessage: 'Order',
  },
  DeliveryTo: {
    id: 'DeliveryTo',
    defaultMessage: 'Delivery to',
  },
  Assigned: {
    id: 'Assigned',
    defaultMessage: 'Assigned',
  },
  OnRoute: {
    id: 'OnRoute',
    defaultMessage: 'On route',
  },
  BuildRoute: {
    id: 'BuildRoute',
    defaultMessage: 'Build route',
  },
  Km: {
    id: 'Km',
    defaultMessage: 'Km',
  },
  Appoint: {
    id: 'Appoint',
    defaultMessage: 'Appoint',
  },
  AppointmentCourier: {
    id: 'AppointmentCourier',
    defaultMessage: 'Appointment of a courier',
  },
  Car: {
    id: 'Car',
    defaultMessage: 'Car',
  },
  Moto: {
    id: 'Moto',
    defaultMessage: 'Moto',
  },
  DeleteRouteWarning: {
    id: 'DeleteRouteWarning',
    defaultMessage: 'Are you sure you want to delete this route?',
  },
  CannotUndone: {
    id: 'CannotUndone',
    defaultMessage: 'This action cannot be undone',
  },
  BreakFreeIn: {
    id: 'BreakFreeIn',
    defaultMessage: 'Break free in',
  },
  RouteCreatedSuccessfully: {
    id: 'RouteCreatedSuccessfully',
    defaultMessage: 'Route created successfully',
  },
  AssemblyTo: {
    id: 'AssemblyTo',
    defaultMessage: 'Assembly to',
  },
  IsDeliveryTo: {
    id: 'IsDeliveryTo',
    defaultMessage: 'Delivery to',
  },
  ChangedAmount: {
    id: 'ChangedAmount',
    defaultMessage: 'Changed amount',
  },
  OrderCollected: {
    id: 'OrderCollected',
    defaultMessage: 'Order collected',
  },
  OrderPicked: {
    id: 'OrderPicked',
    defaultMessage: 'Order picked',
  },
  IsLate: {
    id: 'IsLate',
    defaultMessage: 'Being late:',
  },
  ProductAssembled: {
    id: 'ProductAssembled',
    defaultMessage: 'Product assembled',
  },
  ProductDeleted: {
    id: 'ProductDeleted',
    defaultMessage: 'Product deleted',
  },
  OrderCreated: {
    id: 'OrderCreated',
    defaultMessage: 'Order created',
  },
  OrderInAssembly: {
    id: 'OrderInAssembly',
    defaultMessage: 'Order in assembly',
  },
  CourierTookOrderForDelivery: {
    id: 'CourierTookOrderForDelivery',
    defaultMessage: 'Courier took the order for delivery',
  },
  OrderDeliveredToCustomer: {
    id: 'OrderDeliveredToCustomer',
    defaultMessage: 'Order delivered to customer',
  },
  OrderCancelled: {
    id: 'OrderCancelled',
    defaultMessage: 'Order cancelled',
  },
  OrderPayed: {
    id: 'OrderPayed',
    defaultMessage: 'Order payed',
  },
  Assign: {
    id: 'Assign',
    defaultMessage: 'assign',
  },
  Week: {
    id: 'Week',
    defaultMessage: 'Week',
  },
  Month: {
    id: 'Month',
    defaultMessage: 'Month',
  },
  Quarter: {
    id: 'Quarter',
    defaultMessage: 'Quarter',
  },
  Year: {
    id: 'Year',
    defaultMessage: 'Year',
  },
  Source: {
    id: 'Source',
    defaultMessage: 'Source',
  },
  GeneralAll: {
    id: 'GeneralAll',
    defaultMessage: 'General',
  },
  AllMarkets: {
    id: 'AllMarkets',
    defaultMessage: 'All markets',
  },
  NumberOrdersPerUser: {
    id: 'NumberOrdersPerUser',
    defaultMessage: 'Number of orders per user',
  },
  TotalAmount: {
    id: 'TotalAmount',
    defaultMessage: 'Total amount',
  },
  AverageWeight: {
    id: 'AverageWeight',
    defaultMessage: 'Average weight',
  },
  NewUsers: {
    id: 'NewUsers',
    defaultMessage: 'New users',
  },
  Profit: {
    id: 'Profit',
    defaultMessage: 'Profit',
  },
  FirstMonthUsersOrders: {
    id: 'FirstMonthUsersOrders',
    defaultMessage: 'First month',
  },
  LastMonthUsersOrders: {
    id: 'LastMonthUsersOrders',
    defaultMessage: 'Last month',
  },
  PaymentForGoods: {
    id: 'PaymentForGoods',
    defaultMessage: 'Payment for goods',
  },
  RepeatBuyers: {
    id: 'RepeatBuyers',
    defaultMessage: 'Repeat buyers',
  },
  AverageForPeriod: {
    id: 'AverageForPeriod',
    defaultMessage: 'Average for the period',
  },
  Currency: {
    id: 'Currency',
    defaultMessage: 'Currency',
  },
  SelectMarketFilter: {
    id: 'SelectMarketFilter',
    defaultMessage: 'Select a market to activate the filter',
  },
  AddEmployee: {
    id: 'AddEmployee',
    defaultMessage: 'Add employee',
  },
  Surname: {
    id: 'Surname',
    defaultMessage: 'Surname',
  },
  CollectorR: {
    id: 'CollectorR',
    defaultMessage: 'Collector',
  },
  CourierR: {
    id: 'CourierR',
    defaultMessage: 'Courier',
  },
  WorkingHours: {
    id: 'WorkingHours',
    defaultMessage: 'Working hours',
  },
  SlotsWork: {
    id: 'SlotsWork',
    defaultMessage: 'Slots of work',
  },
  DeleteEmployee: {
    id: 'DeleteEmployee',
    defaultMessage: 'Delete employee',
  },
  DeletingEmployee: {
    id: 'DeletingEmployee',
    defaultMessage: 'Deleting employee',
  },
  WarningDeletingEmployee: {
    id: 'WarningDeletingEmployee',
    defaultMessage: 'Removal {courier} from the schedule for {date}?',
  },
}

export default messages
