import { MessageKeys } from '../messages'
import countries from './countries'

const ru: { [x in MessageKeys]: string } = {
  ...countries,
  CurrentOrders: 'Текущие заказы',
  Archive: 'Архив',
  DownloadReport: 'Скачать отчет',
  DownloadReportIn: 'Скачать отчет в {format}',
  OrdersByUsers: 'Заказы по покупателям',
  Filter: 'Фильтр',
  SearchByOrders: 'Поиск по номеру заказа, адресу или телефону',
  City: 'Город',
  CityShort: 'г.',
  Market: 'Рынок',
  Today: 'Сегодня',
  Tomorrow: 'Завтра',
  Yesterday: 'Вчера',
  OrderFilter: 'Фильтр заказов',
  Customer: 'Покупатель',
  Name: 'Имя',
  Phone: 'Телефон',
  ClientType: 'Тип клиента',
  CallMeIf: 'Позвоните мне, если',
  NotCallMeIf: 'Не звоните мне, если',
  Collector: 'Сборщик',
  AssignCollector: 'Назначить сборщика',
  NotBeenAssigned: 'Не был назначен',
  NotAssigned: 'Не назначен',
  Picker: 'Комплектовщик',
  AssignPicker: 'Назначить комплектовщика',
  SelectPicker: 'Выберите комплектовщика',
  Courier: 'Курьер',
  AssignCourier: 'Назначить курьера',
  SelectCourier: 'Выберите курьера',
  Save: 'Сохранить',
  Cancel: 'Отмена',
  Receipt: 'Получение',
  Address: 'Адрес',
  Pickup: 'Самовывоз',
  Delivery: 'Доставка',
  DeliveryDate: 'Дата доставки',
  DeliveryTime: 'Время доставки',
  ChangeReceiveMethod: 'Изменение способа получения',
  OrderCreatedAt: 'Заказ сделан {date} в {time}',
  WebSite: 'Веб-сайт',
  MobileApp: 'Моб. приложение',
  OrderHistory: 'История заказа',
  AssemblyNotLaterThan: 'Сборка, не позднее',
  TakenForAssembly: 'Взят на сборку/собран',
  BeingLate: 'Опоздание',
  Min: 'Мин',
  No: 'Нет',
  DeliveryStartBefore: 'Доставка, начало до',
  TakenDelivery: 'Взят на доставку',
  DeliveryToDoor: 'Доставка до двери до',
  Delivered: 'Доставлен',
  NamePlace: 'Название',
  PickupTime: 'Время самовывоза',
  PaymentLink: 'Ссылка на оплату',
  DeliveryCost: 'Стоимость доставки',
  TotalPayable: 'Итого к оплате',
  CustomerComment: 'Комментарий покупателя',
  Comment: 'Комментарий',
  EditComment: 'Изменить комментарий',
  AddComment: 'Добавить комментарий',
  Payment: 'Оплата',
  PaymentMethod: 'Способ оплаты',
  Cash: 'Наличными',
  Card: 'Картой',
  Refund: 'Возврат',
  ExtraPaid: 'Доплачен',
  Surcharge: 'Доплата',
  Paid: 'Оплачено',
  NotPaid: 'Не оплачено',
  SureRemoveItemFromOrder: 'Уверены, что хотите удалить товар из заказа?',
  NotCancelRemoveItemOrder: 'Вернуть товар в заказ будет нельзя',
  ValueMustNumber: 'Значение должно быть числом',
  QuantityItemMustLess: 'Количество товара должно быть меньше текущего',
  QuantityPcsMustInteger: 'Количество штучного товара должно быть целым числом',
  OrderList: 'Состав заказа',
  OrderWeight: 'Вес заказа',
  AddProducts: 'Добавить товары',
  AdditionalOrder: 'Дозаказ',
  Seller: 'Продавец',
  Place: 'Место',
  SettlementAmount: 'Сумма расчёта',
  NoSeller: 'Без продавца',
  CustomerReview: 'Отзыв покупателя',
  Assessment: 'Оценка',
  Review: 'Отзыв',
  Date: 'Дата',
  TimeSlot: 'Таймслот',
  Status: 'Статус',
  TotalOrder: 'Итог по заказу',
  Number: 'Номер',
  CollectorCourier: 'Сборщик/Курьер',
  KmFromMarket: 'Км от рынка',
  Picked: 'Укомплектован',
  Collected: 'Собран',
  NotCollected: 'Не собран',
  Late: 'Опаздывает',
  SureChangeOrderStatus: 'Уверены, что хотите изменить статус заказа?',
  NotCancelChangeOrderStatus: 'Изменить статус обратно на такой же будет нельзя',
  SureChangeOrderPayment: 'Уверены, что хотите изменить способ оплаты?',
  NoCancelChangePaymentFromCashToCard:
    'Изменить способ оплаты обратно с «Наличными» на «Картой» будет нельзя. Продолжить?',
  OrderCannotCompleted: 'Заказ нельзя завершить пока покупатель не произведет оплату онлайн',
  OrdersProgress: 'Заказов в работе',
  OnlyOnShift: 'Только на смене',
  HideCollectors: 'Скрыть сборщиков',
  Collectors: 'Сборщики',
  Couriers: 'Курьеры',
  Managers: 'Менеджеры',
  History: 'История',
  HistoryNotAvailable: 'История недоступно',
  DuringAllTime: 'За всё время',
  ThisMonth: 'В этом календарном месяце',
  LastMonth: 'В прошлом календарном месяце',
  BeforeLastMonth: 'В позапрошлом месяце',
  OverTwoMonthsAgo: 'Больше 2 месяцев назад',
  TotalOrders: 'Всего заказов',
  LastOrder: 'Последний заказ',
  Monitoring: 'Мониторинг',
  CollectorsAndCouriers: 'Сборщики и курьеры',
  SlotsLoad: 'Загруженность слотов',
  ShiftSchedule: 'Расписание смены',
  ShiftIndicators: 'Показатели смены',
  OrdersMap: 'Заказы на карте',
  Back: 'Назад',
  Customers: 'Покупатели',
  CustomersR: 'Покупателей',
  UsersR: 'Пользователей',
  SearchByPhoneName: 'Поиск по телефону или имени',
  PhoneAndName: 'Телефон и имя',
  FirstOrder: 'Первый заказ',
  Registration: 'Регистрация',
  Orders: 'Заказы',
  DeliveryAddresses: 'Адреса доставки',
  CustomerNoOrder: 'Покупатель пока не делал заказов',
  ShowMore: 'Показать ещё',
  B2B: 'B2B',
  B2C: 'B2C',
  AverageBill: 'Средний чек',
  From: 'От',
  DelayedMinutes: 'Задержан на {minutes} минут',
  OrdersReviews: 'Отзывы по заказам',
  CustomerNoReviews: 'Покупатель пока не оставлял отзывов',
  User: 'Пользователь',
  OnModeration: 'На модерации',
  ProductsModerationRules: 'Правила модерации товаров',
  SearchByProductOrEntity: 'Поиск по наименованию товара или юр лицу',
  ProductPhoto: 'Фото товара',
  ProductName: 'Наименование товара',
  Actions: 'Действия',
  Approve: 'Одобрить',
  Reject: 'Отклонить',
  Category: 'Категория',
  Subcategory: 'Подкатегория',
  PastValue: 'Прошлое значение',
  Description: 'Описание',
  TaskAt: 'Задание #{num} от {date}',
  RejectionReason: 'Причина отказа',
  Approved: 'Одобрен',
  Rejected: 'Отклонён',
  New: 'Новый',
  Repeated: 'Повторный',
  Pcs: 'шт',
  Kg: 'кг',
  Gram: 'г',
  Litre: 'л',
  Millilitre: 'мл',
  UnitMeasureShort: 'Ед. изм.',
  SaleBy: 'Продажа по ({value})',
  WeightOfOnePiece: 'Вес одной шт.',
  PurchaseStep: 'Шаг покупки',
  PricePer: 'Цена за ({value})',
  EntityShort: 'Юр лицо',
  StoreNameAndNumber: 'Имя и номер лавки',
  MarketingTags: 'Маркетинговые теги',
  SelectMarket: 'Выберите рынок',
  Add: 'Добавить',
  SureWantRemoveTag: 'Вы действительно хотите удалить тег?',
  Tag: 'Тег',
  ChangeProductTag: 'Изменение тега продукта',
  ProductChange: 'Изменение товара',
  AddingProduct: 'Добавление товара',
  Sellers: 'Продавцы',
  Markets: 'Рынки',
  Notifications: 'Уведомления',
  Analytics: 'Аналитика',
  Tags: 'Теги',
  Moderation: 'Модерация',
  RegisterSeller: 'Зарегистрировать продавца',
  FullRegistrationSeller: 'Полная регистрация продавца (ИП или ООО)',
  InProcessRegistration: 'В процессе регистрации',
  ListTemplatesInProgress: 'Список шаблонов в процессе заполнения',
  ShowcasesSellers: 'Витрины продавцов',
  InformationSellersProducts: 'Информация о всех продавцах и их товарах',
  CopyProducts: 'Копировать товары',
  CopyingAllProductsInfo:
    'Копирование всех товаров от одного продавца к другому' +
    ' (если товары переносятся в "Общего" продаваца то "место" заполняется из профиля)',
  SystemVendors: 'Системные продавцы',
  SellersInProcessRegistration: 'Продавцы в процессе регистрации',
  SureDeleteSellerProfile: 'Вы действительно хотите удалить профиль продавца {seller}?',
  Confirm: 'Подтвердить',
  ShowcaseSeller: 'Витрина продавца',
  Missing: 'Отсутствует',
  SureDeleteProduct: 'Вы действительно хотите удалить товар',
  Hidden: 'Скрыт',
  All: 'Все',
  Active: 'Активные',
  HiddenS: 'Скрытые',
  SellerPlace: 'Место продавца',
  AddNewProduct: 'Добавить новый товар',
  EditingSellerShowcase: 'Редактирование витрины продавца',
  Polygons: 'Полигоны',
  Slots: 'Слоты',
  Assortment: 'Ассортимент',
  Catalog: 'Каталог',
  EditPolygons: 'Редактировать полигоны',
  OpeningHours: 'Часы работы',
  DeliveryHours: 'Часы доставки',
  MonSunFromTo: 'пн-вс c {startTime} до {endTime}',
  SomeIntervalsNotSaved: 'Некоторые интервалы не были сохранены',
  CannotCreateIntervalsOverlapAnother: 'Нельзя создать интервалы, которые накладываются один на другой.',
  DeliverySlots: 'Слоты доставки',
  DeliverySlotsNotSet: 'Слоты доставки ещё не заданы',
  HereConfigureDeliverySlots: 'Здесь вы можете добавить и настроить слоты доставки',
  AddSlots: 'Добавить слоты',
  AddSlot: 'Добавить слот',
  BestFromMarket: 'Лучшее с рынка',
  Stalls: 'Лавки',
  SearchByProducts: 'Поиск по товарам',
  SearchBySellers: 'Поиск по продавцам',
  ShopsNotFound: 'Лавки не найдены',
  ProductsNotFound: 'Товары не найдены',
  StallsSearchEmpty: 'Проверьте запрос. Возможно, в нём ошибка. Или поищите товары в лучшем с рынка.',
  BestFromMarketSearchEmpty: 'Проверьте запрос. Возможно, в нём ошибка. Или поищите товары в лавках.',
  AssortmentNotCreated: 'Ассортимент ещё не создан',
  HereCreateCategoriesSubcategories: 'Здесь вы можете создать категории и подкатегории для рынка',
  CreateAssortment: 'Создать ассортимент',
  CategoriesAndSubcategories: 'Категории и подкатегории',
  Edit: 'Редактировать',
  Categories: 'Категории',
  Subcategories: 'Подкатегории',
  AddSubcategory: 'Добавить подкатегорию',
  FinishEditing: 'Завершить редактирование',
  SubcategoriesR: 'Подкатегорий',
  GoodsR: 'Товаров',
  AddedProducts: 'Добавлено товаров',
  CategoryHidden: 'Сейчас категория скрыта в приложении',
  CategoryHiddenInfo: 'Пользователи не будут видеть её в категориях',
  SubcategoryHidden: 'Сейчас подкатегория скрыта в приложении',
  SubcategoryHiddenInfo: 'Пользователи не будут видеть её товары в категории',
  Other: 'Другое',
  UpdateProductRatings: 'Обновить рейтинги товаров',
  SureUpdateRatingsInSubcategory: 'Уверены, что хотите обновить рейтинги всех товаров в подкатегории?',
  UpdateRatingsWarning:
    'После обновления все товары в подкатегории будут иметь реальный рейтинг,' +
    ' который зависит от частоты покупки товара. Соответственно, их порядок выдачи в приложении изменится.',
  Refresh: 'Обновить',
  AddCategory: 'Добавить категорию',
  CategoryName: 'Название категории',
  CategoryCurrentlyHidden: 'Сейчас категория скрыта в приложении',
  CategoryIsVisible: 'Сейчас категория видна в приложении',
  ClickCategoryVisible: 'Нажмите, чтобы сделать категорию видимой',
  ClickHideCategory: 'Нажмите, чтобы скрыть категорию',
  OnlyCategoryCannotHidden: 'Единственную категорию в ассортименте скрыть нельзя',
  OnlyCategoryCannotDeleted: 'Единственную категорию в ассортименте удалить нельзя',
  RemovingCategory: 'Удаление категории «{category}»',
  DeletingCategoryWarning:
    'Удаление категории приведет к удалению всех подкатегорий в этой категории.' +
    ' Это действие нельзя будет отменить. Удалить?',
  ImageEditing: 'Редактирование изображения',
  SubcategoryName: 'Название подкатегории',
  SubcategoryCurrentlyHidden: 'Сейчас подкатегория скрыта в приложении.',
  SubcategoryIsVisible: 'Сейчас подкатегория видна в приложении.',
  ClickHideSubcategory: 'Нажмите, чтобы скрыть подкатегорию',
  ClickSubcategoryVisible: 'Нажмите, чтобы сделать подкатегорию видимой',
  MarketName: 'Название рынка',
  TagName: 'Название тега',
  SignIn: 'Войти',
  Login: 'Логин',
  EnterLogin: 'Введите логин',
  Password: 'Пароль',
  EnterPassword: 'Введите пароль',
  Error: 'Ошибка',
  FieldRequired: 'Это поле обязательно',
  ForSpecificCustomers: 'Для конкретных покупателей',
  SureRemoveNotification: 'Вы действительно хотите удалить уведомление "{title}"?',
  Notification: 'Уведомление',
  Header: 'Заголовок',
  Text: 'Текст',
  DispatchTime: 'Время отправки',
  Type: 'Тип',
  Monday: 'Понедельник',
  Tuesday: 'Вторник',
  Wednesday: 'Среда',
  Thursday: 'Четверг',
  Friday: 'Пятница',
  Saturday: 'Суббота',
  Sunday: 'Воскресение',
  DaysOfWeek: 'Дни недели',
  Periodicity: 'Периодичность',
  StartingFromCurrent: 'Начиная с текущей',
  HowManyWeeksRepeat: 'Через сколько недель повторение',
  UserGroup: 'Пользовательская группа',
  EnterClientNumberOrName: 'Введите номер или имя клиента',
  NotLessAndNotMoreCharacters: 'Не менее {less} и не более {more} символов',
  IncorrectFormat: 'Некорректный формат',
  SentNotifications: 'Отправленные уведомления',
  Quantity: 'Количество',
  DepartureDate: 'Дата отправки',
  Single: 'Одиночный',
  Scheduled: 'По расписанию',
  AllUsers: 'Все пользователи',
  RegisteredUsers: 'Зарегистрированные пользователи',
  UnregisteredUsers: 'Незарегистрированные пользователи',
  UserType: 'Тип пользователя',
  SignOut: 'Выйти из учетной записи?',
  Yes: 'Да',
  Admin: 'Админ',
  Manager: 'Менеджер',
  Operator: 'Оператор',
  SelectTags: 'Выберите теги',
  ChangeHowReceive: 'Изменение способа получения',
  DeliveryAddress: 'Адрес доставки',
  EnterAddress: 'Укажите дату доставки',
  EnterDeliveryDate: 'Укажите дату доставки',
  SpecifyDeliveryTime: 'Укажите время доставки',
  Apply: 'Применить',
  Reset: 'Сбросить',
  Delete: 'Удалить',
  QuantityGoods: 'Количество товара',
  EnterTitle: 'Введите название',
  EditTag: 'Редактировать тег',
  CreateTag: 'Создать тег',
  Color: 'Цвет',
  Priority: 'Приоритет',
  Image: 'Изображение',
  Download: 'Загрузить',
  NoMoreThanCharacters: 'Не более {length} символов',
  StallsSellers: 'Лавки (продавцы)',
  ProductLinks: 'Ссылки на товар',
  ThroughCategories: 'Через категории',
  ThroughSeller: 'Через продавца',
  Country: 'Страна',
  VendorCode: 'Артикул',
  Rating: 'Рейтинг',
  Unit: 'Единица измерения',
  WeightOnePieceGrams: 'Вес одной штуки в граммах',
  SellBy: 'Продажа по ({unit})',
  DiscountPriceFor: 'Цена со скидкой за ({unit})',
  OriginalPrice: 'Исходная цена',
  UpdatePriceWithCommission: 'Обновить цену с учетом комиссии ({commission}%)',
  PriceWithoutDiscountFor: 'Цена без скидки за ({unit})',
  CreateCopy: 'Создать копию',
  ValueMustGreaterThan: 'Значение должно быть больше {value}',
  ValueMustNotExceedCharacters: 'Значение не должно превышать {limit} символов',
  ValueMustNumberGreaterThan: 'Значение должно быть числом и больше {value}',
  MustAddImage: 'Добавьте изображение',
  Added: 'Добавлено',
  ShopName: 'Имя лавки',
  SellerNameNickname: 'Имя продавца/Никнейм',
  PhotoYourShop: 'Фото вашей лавки',
  SellerPhoto: 'Фото продавца',
  AssignOrder: 'Назначить заказ',
  FieldMustNotEmpty: 'Поле не должно быть пусты',
  Weight: 'Вес',
  ForAmount: 'На сумму',
  InAssembly: 'В сборке',
  AtCourier: 'У курьера',
  OnDelivery: 'На доставке',
  Statuses: 'Статусы',
  Canceled: 'Отменён',
  Period: 'Период',
  Users: 'Пользователи',
  NewRegisteredUsers: 'Новые зарегистрированные пользователи',
  DeliveredMany: 'Доставленные',
  CanceledMany: 'Отменённые',
  NumberCustomers: 'Количество покупателей',
  NumberCustomersLeastOneOrder: 'Количество покупателей, которые сделали хотя бы 1 заказ (доставленный)',
  OfTheseNumberFirst: 'Из них кол-во первых',
  NumberCustomersFirstOrder: 'Количество покупателей, которые сделали заказ (доставленный) впервые',
  NumberOrdersFirstMonth: 'Количество заказов пользователей (первый месяц)',
  AverageOrdersFirstMonth:
    'Среднее число заказов за первый месяц пользования после регистрации для юзеров,' +
    ' созданных с момента начала выбранного интервала',
  NumberOrdersLastMonth: 'Количество заказов пользователей (последний месяц)',
  AverageOrdersLastMonth:
    'Среднее число заказов за последний месяц пользования после регистрации для юзеров,' +
    ' созданных до момента окончания выбранного интервала',
  Turnover: 'Оборот',
  CommissionPlatform: 'Комиссия Wow! Food',
  TotalWeight: 'Общий вес',
  Total: 'Итого',
  TotalStats: 'Общая статистика',
  OrderCard: 'Карточка заказа',
  ItemRejection: 'Отклонение товара',
  SpecifyReason: 'Укажите причину',
  DeliverySlot: 'Слот доставки',
  CustomerCard: 'Карточка покупателя',
  ItemsReport: 'Отчет по товарам',
  Polygon: 'Полигон',
  CopyingProducts: 'Копирование товаров',
  SelectSellers: 'Выберите продавцов',
  SellerCopyFrom: 'От какого продавца копировать',
  SellerCopyTo: 'Какому продавцу копировать',
  CopiedSuccessfully: 'Все товары успешно скопированы',
  Completed: 'Завершено',
  PolygonsNotSet: 'Полигоны доставки еще не заданы',
  ConfigurePolygons: 'Здесь вы можете добавить и настроить полигоны доставки',
  AddPolygons: 'Добавить полигоны',
  AddPolygon: 'Добавить полигон',
  PolygonDisabledToday:
    'Полигон выключен на сегодняшний день ({time}).' +
    ' Это значит, что пользователи в его пределах не могут заказать доставку в приложении на сегодня',
  PolygonDisabledUnlimited:
    'Полигон выключен на неопределенное время.' +
    ' Это значит, что пользователи в его пределах не могут заказать доставку в приложении',
  AvailableOrderTime: 'Доступное время заказа',
  PriceDelivery: 'Цена доставки',
  MinimumOrder: 'Минимальный заказ',
  AsSoonAsPossible: 'Как можно скорее',
  BySlotNearest: 'По слотам (ближайший слот)',
  BySlotsThroughOne: 'По слотам (через один слот)',
  BySlotsThroughTwo: 'По слотам (через два слота)',
  AllDay: 'Весь день',
  AddAvailableTime: 'Добавить доступное время',
  Clear: 'Очистить',
  PolygonShutdown: 'Выключение полигона',
  DisableDeliverySlotsToday:
    'Выключить слоты доставки на сегодня ({time}) Пользователи смогут заказать только на завтра',
  DisableDeliverySlotsUnlimited: 'Выключить полигон доставки на неограниченное время',
  SwitchOff: 'Выключить',
  FromWith: 'С',
  Before: 'До',
  SlotOverloaded: 'Слот перегружен. Закройте его или увеличьте мощности',
  CloseSlot: 'Закрыть слот',
  OpenSlot: 'Открыть слот',
  SlotLoad: 'Загруженность слота',
  FactPlan: 'Факт / план',
  CouriersSlot: 'Курьеров на слоте',
  PickersSlot: 'Сборщиков на слоте',
  ManagersSlot: 'Менеджеров на слоте',
  DownloadIndex: 'Индекс загрузки',
  CallMe: 'Позвоните мне',
  NotCallMe: 'Не звоните мне',
  IfProductOutOfStock: 'Если товар закончился или оказался некачественным',
  PickReplacement: 'Подберите замену',
  DeleteProduct: 'Удалите товар',
  IfWeightItemChanged: 'Если изменился вес товара',
  RemoveItemIfWeightChanges: 'Удалите товар, если его вес меняется больше, чем на 10%',
  ChangeItemWeight: 'Измените вес товара',
  AboutProductReplacement: 'Насчёт замены товара',
  AboutDeletingItem: 'Насчёт удаления товара',
  IfWeightItemChanges: 'Если изменится вес товара',
  IfNotAnswer: 'Если не отвечу',
  DuringDay: 'В течение дня',
  PayAttention: 'Обратите внимание',
  Mon: 'Пн',
  Tue: 'Вт',
  Wed: 'Ср',
  Thu: 'Чт',
  Fri: 'Пт',
  Sat: 'Сб',
  Sun: 'Вс',
  Daily: 'Ежедневно',
  ByDayWeek: 'По дням недели',
  Once: 'Один раз',
  Weekly: 'Еженедельно',
  ToAllCustomers: 'Всем покупателям',
  ToRegisteredCustomers: 'Зарегистрированным покупателям',
  ToNotRegisteredCustomers: 'Не зарегистрированным покупателям',
  EveryWeeks: 'Каждые {num} недель',
  NotShift: 'Не на смене',
  OnShift: 'На смене',
  DeliversOrder: 'Везет заказ',
  Free: 'Свободен',
  CollectsOrder: 'Собирает заказ',
  ProductCreationError: 'Ошибка создания товара',
  ProductUpdateError: 'Ошибка обновления товара',
  ProductDeletionError: 'Ошибка удаления товара',
  ProductCopyError: 'Ошибка копирования товара',
  ProductApproved: 'Товар одобрен и перемещен в Архив',
  ProductRejected: 'Товар отклонен и перемещен в Архив',
  Successfully: 'Успешно',
  ModerationUpdateError: 'Ошибка обновления статуса модерации товара',
  General: 'Общий',
  CustomerOrders: 'Заказы покупателей',
  Products: 'Товары',
  ProductsChain: 'Связи товаров',
  DefaultProductGroup: 'Группа товаров по умолчанию',
  DefaultProductGroupInfo: 'Показываются к товарам, если для них не настроены связи',
  CreateGroup: 'Создать группу',
  SpecificProductsLinks: 'Связи конкретных товаров',
  SpecificProductsLinksInfo: 'Здесь вы можете добавить связи между товарами',
  AddLinks: 'Добавить связи',
  ProductsSelected: 'Выбрано товаров',
  ResetAllProducts: 'Сбросить все товары',
  TieUp: 'Связать',
  Change: 'Изменить',
  Group: 'Группа',
  RemovingGroup: 'Уверены, что хотите удалить группу по умолчанию?',
  RemovingGroupWarning: 'Это действие будет нельзя отменить',
  ItemToTie: 'Товар, который связать',
  WhatTie: 'Что связать',
  WhatToConnectWith: 'С чем связать',
  Select: 'Выбрать',
  Link: 'Связь',
  Hide: 'Скрыть',
  HideOff: 'Сделать видимой',
  RemovingLink: 'Уверены, что хотите удалить эту связь товаров?',
  INN: 'ИНН',
  Selected: 'Выбран',
  SelectAnother: 'Выбрать другой',
  InWork: 'В работе',
  CourierRoutes: 'Маршруты курьеров',
  Route: 'Маршрут',
  ViewRoute: 'Посмотреть маршрут',
  ChangeRoute: 'Изменить маршрут',
  OrderStatus: 'Статус заказа',
  Set: 'Задан',
  NotSet: 'Не задан',
  SetRoute: 'Задать маршрут',
  Order: 'Заказ',
  DeliveryTo: 'Доставка до',
  Assigned: 'Назначено',
  OnRoute: 'В маршруте',
  BuildRoute: 'Построить маршрут',
  Km: 'Км',
  Appoint: 'Назначить',
  AppointmentCourier: 'Назначение курьера',
  Car: 'Автомобиль',
  Moto: 'Мото',
  DeleteRouteWarning: 'Уверены, что хотите удалить этот маршрут?',
  CannotUndone: 'Это действие будет нельзя отменить',
  BreakFreeIn: 'Освободиться в',
  RouteCreatedSuccessfully: 'Маршрут успешно создан',
  AssemblyTo: 'Сборка до',
  IsDeliveryTo: 'Доставка до',
  ChangedAmount: 'Изменено количество товара',
  OrderCollected: 'Заказ укомплектован',
  OrderPicked: 'Заказ собран',
  IsLate: 'Опоздание:',
  ProductAssembled: 'Товар собран',
  ProductDeleted: 'Товар удален',
  OrderCreated: 'Заказ создан',
  OrderInAssembly: 'Заказ в сборке',
  CourierTookOrderForDelivery: 'Курьер взял заказ на доставку',
  OrderDeliveredToCustomer: 'Заказ доставлен покупателю',
  OrderCancelled: 'Заказ отменен',
  OrderPayed: 'Заказ оплачен',
  Assign: 'назначен',
  Week: 'Неделя',
  Month: 'Месяц',
  Quarter: 'Квартал',
  Year: 'Год',
  Source: 'Источник',
  GeneralAll: 'Общее',
  AllMarkets: 'Все рынки',
  NumberOrdersPerUser: 'Кол-во заказов на пользователя',
  TotalAmount: 'Общее количество',
  AverageWeight: 'Средний вес',
  NewUsers: 'Новые пользователи',
  Profit: 'Прибыль',
  FirstMonthUsersOrders: 'Первый месяц',
  LastMonthUsersOrders: 'Последний месяц',
  PaymentForGoods: 'Оплата товара',
  RepeatBuyers: 'Повторные покупатели',
  AverageForPeriod: 'Среднее за период',
  Currency: 'Валюта',
  SelectMarketFilter: 'Выберите рынок для активации фильтра',
  AddEmployee: 'Добавить сотрудника',
  Surname: 'Фамилия',
  CollectorR: 'Сборщика',
  CourierR: 'Курьера',
  WorkingHours: 'Время работы',
  SlotsWork: 'Слоты работы',
  DeleteEmployee: 'Удалить сотрудника',
  DeletingEmployee: 'Удаление сотрудника',
  WarningDeletingEmployee: 'Удалить {courier} из расписания на {date}?',
}

export default ru
